import request from '@/utils/request'

// 查询多车多泊位停车纪录列表
export function listMultipleFlow(query) {
	return request({
		url: '/park/multipleFlow/list',
		method: 'get',
		params: query
	})
}

// 查询多车多泊位停车纪录详细
export function getMultipleFlow(id) {
	return request({
		url: '/park/multipleFlow/' + id,
		method: 'get'
	})
}

// 新增多车多泊位停车纪录
export function addMultipleFlow(data) {
	return request({
		url: '/park/multipleFlow',
		method: 'post',
		data: data
	})
}

// 修改多车多泊位停车纪录
export function updateMultipleFlow(data) {
	return request({
		url: '/park/multipleFlow',
		method: 'put',
		data: data
	})
}

// 删除多车多泊位停车纪录
export function delMultipleFlow(id) {
	return request({
		url: '/park/multipleFlow/' + id,
		method: 'delete'
	})
}
