var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "digital-scroll-container" }, [
    _c(
      "div",
      { class: "digital-scroll-type-" + _vm.digitalType },
      [
        _c("div", { staticClass: "panel-title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("count-to", {
          staticClass: "panel-num",
          attrs: {
            separator: "",
            prefix: _vm.prefix,
            decimals: _vm.decimals,
            "start-val": _vm.startValue,
            "end-val": _vm.value,
            duration: 1000,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }