<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import resize from './mixins/resize';

import {getDeviceStatisticByTypeStatus} from '@/api/park/device';

const animationDuration = 6000

export default {
  mixins: [resize],
  dicts: ['park_device_type', 'park_device_outer_type', 'park_device_dici_type'],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null,
      curIndex: 0,

      deviceArr: [],
      onlineArr: [],
      offlineArr: [],
    }
  },
  mounted() {
    // this.initData()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    if (this.timer) {
        clearInterval(this.timer);
    }
  },
  methods: {
    onDictReady() {
        this.initData();
    },

    initData() {
        let that = this;
        getDeviceStatisticByTypeStatus({deptId: 0}).then((response) => {
            if (response.rows && response.rows.length > 0) {
                let dtsMap = {};
                for (const dts of response.rows) {
                    if (!dtsMap[dts.deviceType]) {
                        dtsMap[dts.deviceType] = [0, 0]
                    }
                    if (dts.deviceStatus == 0) {
                        dtsMap[dts.deviceType][0] = dts.count;
                    } else {
                        dtsMap[dts.deviceType][1] = dts.count;
                    }
                }
                var index = 0
                for (var key in dtsMap) {
                    let a = this.dict.label.park_device_type[key];
                    let b = this.dict.label.park_device_outer_type[key];
                    let c = this.dict.label.park_device_dici_type[key]

                    this.deviceArr[index] = this.dict.label.park_device_type[key] || this.dict.label.park_device_outer_type[key] || this.dict.label.park_device_dici_type[key]
                    this.onlineArr[index] = dtsMap[key][0]
                    this.offlineArr[index] = dtsMap[key][1]

                    index++
                }

                this.$nextTick(() => {
                    this.initChart()
                    this.dynamicSelect()
                })

                // dtsMap.forEach((ele, index) => {

                // });
            }
        });
    },

    initChart() {
      this.chart = this.$echarts.init(this.$el)

      this.chart.setOption({
        title: {
            text: "设备数据",
            width: 20,
            height: 163,
            fontSize: 20,
            textStyle: {
                color: this.$store.state.commonParams.themeParams['commonFgText']|| "#1a2138",
                fontSize: "18",
            },
        },
        tooltip: {
            trigger: "axis",
            extraCssText: 'z-index: 2',
            backgroundColor: this.$store.state.commonParams.themeParams['commonBg3'] || "#edf1f7",
            textStyle: {
                color: this.$store.state.commonParams.themeParams['commonFgText'],
                fontSize: 12
            },
            axisPointer: {
                type: 'shadow',
                label: { show: true, backgroundColor: 'transparent' },
                shadowStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            { offset: 0, color: 'rgba(100, 101, 171, 0)' },
                            { offset: 0.5, color: 'rgba(100, 101, 171, 0.2)' },
                            { offset: 1, color: 'rgba(100, 101, 171, 0.6)' },
                            { offset: 1, color: 'rgba(100, 101, 171, 0.6)' },
                        ],
                        global: false,
                    },
                },
            },
            padding: [5, 10],
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.deviceArr,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            rotate: -30,
            align: 'left',
            verticalAlign: 'top'
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '在线',
          type: 'bar',
          stack: 'vistors',
          barWidth: '60%',
          data: this.onlineArr,
          animationDuration
        }, {
          name: '离线',
          type: 'bar',
          stack: 'vistors',
          barWidth: '60%',
          data: this.offlineArr,
          animationDuration
        }]
      })
    },
    dynamicSelect() {
        let _this = this;
        this.timer = setInterval(function () {
            if (!_this.chart) {
                return;
            }
            // // 隐藏提示框
            // _this.chart.dispatchAction({
            //     type: 'hideTip',
            //     seriesIndex: 0,
            //     dataIndex: _this.curIndex
            // });
            // 显示提示框
            _this.chart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: _this.curIndex
            });
            // // 取消高亮指定的数据图形
            // _this.chart.dispatchAction({
            //     type: "downplay",
            //     seriesIndex: 0,
            //     dataIndex: _this.curIndex == 0 ? 5 : _this.curIndex - 1,
            // });
            // _this.chart.dispatchAction({
            //     type: "highlight",
            //     seriesIndex: 0,
            //     dataIndex: _this.curIndex,
            // });
            _this.curIndex++;
            if (_this.curIndex > _this.deviceArr.length) {
                _this.curIndex = 0;
            }
        }, 1000);
    },
  }
}
</script>
