import request from '@/utils/request'

// 查询车场图片列表
export function listCarImage(query) {
	return request({
		url: '/park/carImage/list',
		method: 'get',
		params: query
	})
}

// 查询车场图片详细
export function getCarImage(id) {
	return request({
		url: '/park/carImage/' + id,
		method: 'get'
	})
}

// 新增车场图片
export function addCarImage(data) {
	return request({
		url: '/park/carImage',
		method: 'post',
		data: data
	})
}

// 修改车场图片
export function updateCarImage(data) {
	return request({
		url: '/park/carImage',
		method: 'put',
		data: data
	})
}

// 删除车场图片
export function delCarImage(id) {
	return request({
		url: '/park/carImage/' + id,
		method: 'delete'
	})
}
