<template>
    <div class="navbar">
        <hamburger
            id="hamburger-container"
            :is-active="sidebar.opened"
            class="hamburger-container"
            @toggleClick="toggleSideBar"
        />

        <breadcrumb
            id="breadcrumb-container"
            class="breadcrumb-container"
            v-if="!topNav"
        />
        <top-nav
            id="topmenu-container"
            class="topmenu-container"
            v-if="topNav"
        />
        <div class="right-menu">
            <template v-if="device!=='mobile'">
                <Clock id="header-Clock" class="right-menu-item" :counts="counts" v-hasPermi="['system:other:noticeNum']"/>

                <search id="header-search" class="right-menu-item" />

                <screenfull
                    id="screenfull"
                    class="right-menu-item hover-effect"
                />

                <el-tooltip content="布局大小" effect="dark" placement="bottom">
                    <size-select
                        id="size-select"
                        class="right-menu-item hover-effect"
                    />
                </el-tooltip>
            </template>

            <el-dropdown
                class="avatar-container right-menu-item hover-effect"
                trigger="click"
            >
                <div class="avatar-wrapper">
                    <img :src="avatar" class="user-avatar" />
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/user/profile">
                        <el-dropdown-item>个人中心</el-dropdown-item>
                    </router-link>
                    <el-dropdown-item @click.native="setting = true">
                        <span>布局设置</span>
                    </el-dropdown-item>
                    <el-dropdown-item divided @click.native="logout">
                        <span>退出登录</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="current-status">
            <div class="current-time">
                {{ curTime.pattern("yyyy-MM-dd HH:mm:ss EEE") }}
            </div>
            <!-- <div class="current-time"> {{ curTime }} </div> -->
            <iframe
                class="current-weather"
                scrolling="no"
                :src="
                    'https://widget.tianqiapi.com/?style=tz&skin=pitaya&fontsize=13&align=right' +
                    weatherColor
                "
                frameborder="0"
                allowtransparency="true"
            ></iframe>
        </div>
        <call-warning></call-warning>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";
import CallWarning from '@/components/CallWarning';
import { pattern } from "@/utils/index";
import Clock from "@/components/Clock";
import { getCodeImg } from "@/api/login";
import { noticeNum } from "@/api/other";
import { checkPermi } from "@/utils/permission";
// import {getNoticeNum} from "@/utils/websocket-user"
export default {
    components: {
        Breadcrumb,
        TopNav,
        Hamburger,
        Screenfull,
        SizeSelect,
        Search,
        Clock,
        CallWarning,
    },
    data() {
        return {
            weatherColor: "",
            curTime: new Date(),
            counts: [0, 0, 0],
            second: 0,
        };
    },
    watch: {
        "$store.state.commonParams.themeParams": {
            handler() {
                this.resetWeatherColor();
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["sidebar", "avatar", "device"]),
        setting: {
            get() {
                return this.$store.state.settings.showSettings;
            },
            set(val) {
                this.$store.dispatch("settings/changeSetting", {
                    key: "showSettings",
                    value: val,
                });
            },
        },
        topNav: {
            get() {
                return this.$store.state.settings.topNav;
            },
        },
    },
    mounted() {
        if (checkPermi(['system:other:noticeNum'])) {
            this.requestNoticeNum();
            let _this = this;
            this.timer = setInterval(function () {
                _this.curTime = new Date();
                if (++_this.second > 60) {
                    _this.second = 0;
                    _this.requestNoticeNum();
                }
            }, 1000);
        }

        this.resetWeatherColor();

    },
    methods: {
        requestNoticeNum() {
            noticeNum().then((response) => {
                this.counts = response.data;
            });
        },
        toggleSideBar() {
            this.$store.dispatch("app/toggleSideBar");
        },
        async logout() {
            this.$confirm("确定注销并退出系统吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$store.dispatch("LogOut").then(() => {
                        location.href = "/index";
                    });
                })
                .catch(() => {});
        },
        resetWeatherColor() {
            if (
                this.$store.state.commonParams.themeParams &&
                this.$store.state.commonParams.themeParams["commonFgText"]
            ) {
                let color =
                    this.$store.state.commonParams.themeParams["commonFgText"];
                this.weatherColor = "&color=" + color.replace("#", "");
            } else {
                this.weatherColor = "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/guanchao.scss";

.navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    @include background_color("commonBg");
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .topmenu-container {
        position: absolute;
        @include background_color("commonBg");
        left: 50px;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }

    .current-status {
        float: right;
        height: 100%;
        line-height: 1.5;
        margin-right: 10px;
        width: 350px;

        .current-time {
            line-height: 25px;
            width: 100%;
            text-align: right;
            font-size: 13px;
        }

        .current-weather {
            line-height: 25px;
            width: 100%;
        }
    }

    .right-menu {
        float: right;
        height: 100%;
        line-height: 50px;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            @include font_color("commonFg");
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            margin-right: 30px;

            .avatar-wrapper {
                margin-top: 5px;
                position: relative;

                .user-avatar {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                }

                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
