<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px">
            <el-form-item label="车场" prop="parkId">
                <treeselect v-model="queryParams.parkId" :options="parkOptions" :show-count="true" placeholder="请选择车场"
                            style="width: 220px"/>
            </el-form-item>
            <el-form-item label="第三方平台类型" prop="thridType" label-width="180">
                <el-select v-model="queryParams.thridType" placeholder="请选择第三方平台类型" clearable>
                    <el-option
                        v-for="dict in dict.type.park_thrid_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="第三方关联车场名称" prop="thridName" label-width="200">
                <el-input
                    v-model="queryParams.thridName"
                    placeholder="请输入第三方关联车场名称"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['park:parkThrid:add']"
                >新增
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="success"
                    plain
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['park:parkThrid:edit']"
                >修改
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['park:parkThrid:remove']"
                >删除
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:parkThrid:export']"
                >导出
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:parkThrid:export']"
                >导出
                </el-button>
            </el-col>
            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="parkThridList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="id" align="center" prop="id"/>
            <el-table-column label="车场id" align="center" prop="parkId"/>
            <el-table-column label="车场名称" align="center" prop="parkName"/>
            <el-table-column label="第三方平台" align="center" prop="thridType">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.park_thrid_type" :value="scope.row.thridType"/>
                </template>
            </el-table-column>
            <el-table-column label="第三方关联车场id" align="center" prop="thridId"/>
            <el-table-column label="第三方关联车场名称" align="center" prop="thridName"/>
            <el-table-column label="第三方token" align="center" prop="token"/>
            <el-table-column label="第三方推送地址" align="center" prop="businessUrl"/>
            <el-table-column label="第三方回调地址" align="center" prop="callbackUrl"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                        v-hasPermi="['park:parkThrid:edit']"
                    >修改
                    </el-button>
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDelete(scope.row)"
                        v-hasPermi="['park:parkThrid:remove']"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <!-- 添加或修改第三方关联车场对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="700px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="车场" prop="parkId" label-width="60px">
                            <treeselect v-model="form.parkId" :options="parkOptions" :show-count="true"
                                        placeholder="请选择车场"
                                        style="width: 100%"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="第三方平台" prop="thridType" label-width="85px">
                            <el-select v-model="form.thridType" placeholder="请选择第三方平台" style="width: 100%">
                                <el-option
                                    v-for="dict in dict.type.park_thrid_type"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="第三方关联车场id" prop="thridId" label-width="160px" >
                            <el-input v-model="form.thridId" placeholder="请输入第三方关联车场id" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="第三方关联车场名称" prop="thridName" label-width="160px">
                            <el-input v-model="form.thridName" placeholder="请输入第三方关联车场名称"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="第三方token" prop="token" label-width="160px">
                            <el-input v-model="form.token" placeholder="请输入第三方token"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="第三方推送地址" prop="businessUrl" label-width="160px">
                            <el-input v-model="form.businessUrl" placeholder="请输入第三方推送地址"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="第三方回调地址" prop="callbackUrl" label-width="160px">
                            <el-input v-model="form.callbackUrl" placeholder="请输入第三方回调地址"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {addParkThrid, delParkThrid, getParkThrid, listParkThrid, updateParkThrid} from "@/api/park/parkThrid";
    import {treeselect as menuTreeSelect} from "@/api/system/dept";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";

    export default {
        name: "ParkThrid",
        dicts: ['park_thrid_type'],
        components: {Treeselect},
        data() {
            return {
                // 车场树选项
                parkOptions: undefined,
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 第三方关联车场表格数据
                parkThridList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    parkId: null,
                    thridType: null,
                    thridId: null,
                    thridName: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            this.getList();
            this.getParkTree();
        },
        methods: {
            /* 车场列表 */
            getParkTree() {
                menuTreeSelect({'type': 1}).then(response => {
                    this.parkOptions = response.data;
                });
            },
            /** 查询第三方关联车场列表 */
            getList() {
                this.loading = true;
                listParkThrid(this.queryParams).then(response => {
                    this.parkThridList = response.rows;
                    this.total = response.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    parkId: null,
                    thridType: null,
                    thridId: null,
                    thridName: null,
                    delFlag: null,
                    createBy: null,
                    createTime: null,
                    updateBy: null,
                    updateTime: null
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加第三方关联车场";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const id = row.id || this.ids
                getParkThrid(id).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改第三方关联车场";
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            updateParkThrid(this.form).then(response => {
                                this.$modal.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addParkThrid(this.form).then(response => {
                                this.$modal.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const ids = row.id || this.ids;
                this.$modal.confirm('是否确认删除第三方关联车场编号为"' + ids + '"的数据项？').then(function () {
                    return delParkThrid(ids);
                }).then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                }).catch(() => {
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('park/parkThrid/export', {
                    ...this.queryParams
                }, `parkThrid_${new Date().getTime()}.xlsx`)
            }
        }
    };
</script>
