<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            size="small"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="限行名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入限行名称"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="限行类型" prop="type">
                <el-select
                    v-model="queryParams.type"
                    placeholder="请选择限行类型"
                    clearable
                >
                    <el-option
                        v-for="dict in dict.type.park_restrict_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['park:restrict:add']"
                    >新增</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="success"
                    plain
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['park:restrict:edit']"
                    >修改</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['park:restrict:remove']"
                    >删除</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:restrict:export']"
                    >导出</el-button
                >
            </el-col>
            <right-toolbar
                :showSearch.sync="showSearch"
                @queryTable="getList"
            ></right-toolbar>
        </el-row>

        <el-table
            v-loading="loading"
            :data="restrictList"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="限行id" align="center" prop="id" />
            <el-table-column label="限行名称" align="center" prop="name" />
            <el-table-column label="限行类型" align="center" prop="type">
                <template slot-scope="scope">
                    <dict-tag
                        :options="dict.type.park_restrict_type"
                        :value="scope.row.type"
                    />
                </template>
            </el-table-column>
            <el-table-column label="描述" align="center" prop="remark" />
            <el-table-column
                label="周末是否限行"
                align="center"
                prop="weekendRestrict"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.weekendRestrict ? "是" : "否" }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="轮换日期"
                align="center"
                prop="rotationTime"
                width="180"
            >
                <template slot-scope="scope">
                    <span>{{
                        parseTime(scope.row.rotationTime, "{y}-{m}-{d}")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周一限行"
                align="center"
                prop="mondayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.mondayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周二限行"
                align="center"
                prop="tuesdayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.tuesdayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周三限行"
                align="center"
                prop="wednesdayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.wednesdayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周四限行"
                align="center"
                prop="thursdayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.thursdayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周五限行"
                align="center"
                prop="fridayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.fridayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周六限行"
                align="center"
                prop="saturdayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.saturdayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="周日限行"
                align="center"
                prop="sundayRestrict"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.sundayRestrict.replace("|", "和")
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                        v-hasPermi="['park:restrict:edit']"
                        >修改</el-button
                    >
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDelete(scope.row)"
                        v-hasPermi="['park:restrict:remove']"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <!-- 添加或修改车场限行方案数据对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="限行名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入限行名称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="描述" prop="remark">
                            <el-input
                                v-model="form.remark"
                                placeholder="请输入描述"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="限行类型" prop="type">
                            <el-select
                                v-model="form.type"
                                placeholder="请选择限行类型"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="dict in dict.type.park_restrict_type"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="周末是否限行"
                            prop="weekendRestrict"
                        >
                            <el-switch
                                v-model="form.weekendRestrict"
                                active-text="是"
                                inactive-text="否"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row
                    :gutter="10"
                    v-if="
                        form.type ==
                        dict.keyValue.park_restrict_type.generalRestrict
                    "
                >
                    <el-col :span="12">
                        <el-form-item label="轮换日期" prop="rotationTime">
                            <el-date-picker
                                clearable
                                v-model="form.rotationTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择轮换日期"
                                style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row
                    :gutter="10"
                    v-if="
                        form.type ==
                        dict.keyValue.park_restrict_type.generalRestrict
                    "
                >
                    <el-col :span="12">
                        <div class="restrictContent">
                            <h3 style="width:100%;text-align:center;">轮换前限行</h3>
                            <el-form-item
                                label="周一限行"
                                prop="mondayRestrict"
                                v-if="form.mondayRestrictArr"
                            >
                                <el-input-number
                                    v-model="form.mondayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.mondayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周二限行"
                                prop="tuesdayRestrict"
                                v-if="form.tuesdayRestrictArr"
                            >
                                <el-input-number
                                    v-model="form.tuesdayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.tuesdayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周三限行"
                                prop="wednesdayRestrict"
                                v-if="form.wednesdayRestrictArr"
                            >
                                <el-input-number
                                    v-model="form.wednesdayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.wednesdayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周四限行"
                                prop="thursdayRestrict"
                                v-if="form.thursdayRestrictArr"
                            >
                                <el-input-number
                                    v-model="form.thursdayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.thursdayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周五限行"
                                prop="fridayRestrict"
                                v-if="form.fridayRestrictArr"
                            >
                                <el-input-number
                                    v-model="form.fridayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.fridayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                v-if="
                                    form.weekendRestrict &&
                                    form.saturdayRestrictArr
                                "
                                label="周六限行"
                                prop="saturdayRestrict"
                            >
                                <el-input-number
                                    v-model="form.saturdayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.saturdayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                v-if="
                                    form.weekendRestrict &&
                                    form.sundayRestrictArr
                                "
                                label="周日限行"
                                prop="sundayRestrict"
                            >
                                <el-input-number
                                    v-model="form.sundayRestrictArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.sundayRestrictArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="restrictContent">
                            <h3 style="width:100%;text-align:center;">轮换后限行</h3>
                            <el-form-item
                                label="周一限行"
                                prop="mondayRestrictRotation"
                                v-if="form.mondayRestrictRotationArr"
                            >
                                <el-input-number
                                    v-model="form.mondayRestrictRotationArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.mondayRestrictRotationArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周二限行"
                                prop="tuesdayRestrictRotation"
                                v-if="form.tuesdayRestrictRotationArr"
                            >
                                <el-input-number
                                    v-model="form.tuesdayRestrictRotationArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.tuesdayRestrictRotationArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周三限行"
                                prop="wednesdayRestrictRotation"
                                v-if="form.wednesdayRestrictRotationArr"
                            >
                                <el-input-number
                                    v-model="
                                        form.wednesdayRestrictRotationArr[0]
                                    "
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="
                                        form.wednesdayRestrictRotationArr[1]
                                    "
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周四限行"
                                prop="thursdayRestrictRotation"
                                v-if="form.thursdayRestrictRotationArr"
                            >
                                <el-input-number
                                    v-model="
                                        form.thursdayRestrictRotationArr[0]
                                    "
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="
                                        form.thursdayRestrictRotationArr[1]
                                    "
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                label="周五限行"
                                prop="fridayRestrictRotation"
                                v-if="form.fridayRestrictRotationArr"
                            >
                                <el-input-number
                                    v-model="form.fridayRestrictRotationArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.fridayRestrictRotationArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                v-if="
                                    form.weekendRestrict &&
                                    form.saturdayRestrictRotationArr
                                "
                                label="周六限行"
                                prop="saturdayRestrictRotation"
                            >
                                <el-input-number
                                    v-model="
                                        form.saturdayRestrictRotationArr[0]
                                    "
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="
                                        form.saturdayRestrictRotationArr[1]
                                    "
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                            <el-form-item
                                v-if="
                                    form.weekendRestrict &&
                                    form.sundayRestrictRotationArr
                                "
                                label="周日限行"
                                prop="sundayRestrictRotation"
                            >
                                <el-input-number
                                    v-model="form.sundayRestrictRotationArr[0]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                                <span>和</span>
                                <el-input-number
                                    v-model="form.sundayRestrictRotationArr[1]"
                                    :controls="false"
                                    :min="0"
                                    :max="9"
                                    style="width: 80px"
                                />
                            </el-form-item>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listRestrict,
    getRestrict,
    delRestrict,
    addRestrict,
    updateRestrict,
} from "@/api/park/restrict";

export default {
    name: "Restrict",
    dicts: ["park_restrict_type"],
    data() {
        var validatorRotationTime = (rule, value, callback) => {
            if (
                this.form.type ===
                this.dict.keyValue.park_restrict_type.generalRestrict
            ) {
                if (value == null) {
                    callback(new Error("常规限行方案需要配置轮换时间"));
                }
            }
            callback();
        };
        return {
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 车场限行方案数据表格数据
            restrictList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: null,
                type: null,
                remark: null,
                weekendRestrict: null,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    {
                        required: true,
                        message: "限行方案名称不能为空",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "限行类型不能为空",
                        trigger: "blur",
                    },
                ],
                rotationTime: [
                    { validator: validatorRotationTime, trigger: "blur" },
                ],
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        /** 查询车场限行方案数据列表 */
        getList() {
            this.loading = true;
            listRestrict(this.queryParams).then((response) => {
                this.restrictList = response.rows;
                this.total = response.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: null,
                name: null,
                type: null,
                remark: null,
                weekendRestrict: false,
                rotationTime: null,
                mondayRestrict: null,
                tuesdayRestrict: null,
                wednesdayRestrict: null,
                thursdayRestrict: null,
                fridayRestrict: null,
                saturdayRestrict: null,
                sundayRestrict: null,
                mondayRestrictRotation: null,
                tuesdayRestrictRotation: null,
                wednesdayRestrictRotation: null,
                thursdayRestrictRotation: null,
                fridayRestrictRotation: null,
                saturdayRestrictRotation: null,
                sundayRestrictRotation: null,

                mondayRestrictArr: [0, 5],
                tuesdayRestrictArr: [1, 6],
                wednesdayRestrictArr: [2, 7],
                thursdayRestrictArr: [3, 8],
                fridayRestrictArr: [4, 9],
                saturdayRestrictArr: [0, 5],
                sundayRestrictArr: [1, 6],
                mondayRestrictRotationArr: [0, 5],
                tuesdayRestrictRotationArr: [1, 6],
                wednesdayRestrictRotationArr: [2, 7],
                thursdayRestrictRotationArr: [3, 8],
                fridayRestrictRotationArr: [4, 9],
                saturdayRestrictRotationArr: [0, 5],
                sundayRestrictRotationArr: [1, 6],
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加车场限行方案数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getRestrict(id).then((response) => {
                this.form = response.data;
                this.form.mondayRestrictArr =
                    response.data.mondayRestrict.split("|");
                this.form.tuesdayRestrictArr =
                    response.data.tuesdayRestrict.split("|");
                this.form.wednesdayRestrictArr =
                    response.data.wednesdayRestrict.split("|");
                this.form.thursdayRestrictArr =
                    response.data.thursdayRestrict.split("|");
                this.form.fridayRestrictArr =
                    response.data.fridayRestrict.split("|");
                this.form.saturdayRestrictArr =
                    response.data.saturdayRestrict.split("|");
                this.form.sundayRestrictArr =
                    response.data.sundayRestrict.split("|");
                this.form.mondayRestrictRotationArr =
                    response.data.mondayRestrictRotation.split("|");
                this.form.tuesdayRestrictRotationArr =
                    response.data.tuesdayRestrictRotation.split("|");
                this.form.wednesdayRestrictRotationArr =
                    response.data.wednesdayRestrictRotation.split("|");
                this.form.thursdayRestrictRotationArr =
                    response.data.thursdayRestrictRotation.split("|");
                this.form.fridayRestrictRotationArr =
                    response.data.fridayRestrictRotation.split("|");
                this.form.saturdayRestrictRotationArr =
                    response.data.saturdayRestrictRotation.split("|");
                this.form.sundayRestrictRotationArr =
                    response.data.sundayRestrictRotation.split("|");

                this.open = true;
                this.title = "修改车场限行方案数据";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (
                        this.form.type ===
                        this.dict.keyValue.park_restrict_type.generalRestrict
                    ) {
                        this.form.mondayRestrict =
                            this.form.mondayRestrictArr.join("|");
                        this.form.tuesdayRestrict =
                            this.form.tuesdayRestrictArr.join("|");
                        this.form.wednesdayRestrict =
                            this.form.wednesdayRestrictArr.join("|");
                        this.form.thursdayRestrict =
                            this.form.thursdayRestrictArr.join("|");
                        this.form.fridayRestrict =
                            this.form.fridayRestrictArr.join("|");

                        this.form.mondayRestrictRotation =
                            this.form.mondayRestrictRotationArr.join("|");
                        this.form.tuesdayRestrictRotation =
                            this.form.tuesdayRestrictRotationArr.join("|");
                        this.form.wednesdayRestrictRotation =
                            this.form.wednesdayRestrictRotationArr.join("|");
                        this.form.thursdayRestrictRotation =
                            this.form.thursdayRestrictRotationArr.join("|");
                        this.form.fridayRestrictRotation =
                            this.form.fridayRestrictRotationArr.join("|");

                        if (this.form.weekendRestrict) {
                            this.form.saturdayRestrict =
                                this.form.saturdayRestrictArr.join("|");
                            this.form.sundayRestrict =
                                this.form.sundayRestrictArr.join("|");
                            this.form.saturdayRestrictRotation =
                                this.form.saturdayRestrictRotationArr.join("|");
                            this.form.sundayRestrictRotation =
                                this.form.sundayRestrictRotationArr.join("|");
                        } else {
                            this.form.saturdayRestrict = "";
                            this.form.sundayRestrict = "";
                            this.form.saturdayRestrictRotation = "";
                            this.form.sundayRestrictRotation = "";
                        }
                    } else if (
                        this.form.type ===
                        this.dict.keyValue.park_restrict_type.oddEvenRestrict
                    ) {
                        this.form.rotationTime = null;

                        this.form.mondayRestrict = "";
                        this.form.tuesdayRestrict = "";
                        this.form.wednesdayRestrict = "";
                        this.form.thursdayRestrict = "";
                        this.form.fridayRestrict = "";
                        this.form.saturdayRestrict = "";
                        this.form.sundayRestrict = "";
                        this.form.mondayRestrictRotation = "";
                        this.form.tuesdayRestrictRotation = "";
                        this.form.wednesdayRestrictRotation = "";
                        this.form.thursdayRestrictRotation = "";
                        this.form.fridayRestrictRotation = "";
                        this.form.saturdayRestrictRotation = "";
                        this.form.sundayRestrictRotation = "";
                    }
                    if (this.form.id != null) {
                        updateRestrict(this.form).then((response) => {
                            this.$modal.msgSuccess("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        addRestrict(this.form).then((response) => {
                            this.$modal.msgSuccess("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$modal
                .confirm(
                    '是否确认删除车场限行方案数据编号为"' + ids + '"的数据项？'
                )
                .then(function () {
                    return delRestrict(ids);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                "park/restrict/export",
                {
                    ...this.queryParams,
                },
                `restrict_${new Date().getTime()}.xlsx`
            );
        },
    },
};
</script>
<style scoped lang="scss">
.restrictContent {
    @include background_color("commonBg3");
    @include border_color("commonBorder5");
    height: 100%;
    padding: 10px 5px;
}
</style>
