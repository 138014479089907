import themeCommon from '@/assets/styles/theme_params.scss'

const state = {
    themeParams: {},
    baiduMapStyle: {}
}
const mutations = {
    THEME_PARAM_CHANGE: (state, themeName) => {
        state['themeParams'] = {}
        for (let i in themeCommon) {
            if (i.startsWith(themeName + '_')) {
                state['themeParams'][i.replace(themeName + '_', '')] = themeCommon[i]
            }
        }
    },
    BAIDU_MAP_STYLE_CHANGE: (state, themeName) => {
        var json = require('@/assets/json/baiduMapStyle.json');
        if (json.hasOwnProperty(themeName)) {
            state['baiduMapStyle'] = json[themeName];
        } else {
            state['baiduMapStyle'] = json['theme-default'];
        }
    },
}

const actions = {
    // 初始化主题相关参数
    initThemeRelationParam({ commit }, themeName) {
        commit('THEME_PARAM_CHANGE', themeName);
        commit('BAIDU_MAP_STYLE_CHANGE', themeName);
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

