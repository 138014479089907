import request from '@/utils/request'

// 查询月卡操作日志列表
export function listSpecialmonthLog(query) {
	return request({
		url: '/park/specialmonthLog/list',
		method: 'get',
		params: query
	})
}

// 查询月卡操作日志详细
export function getSpecialmonthLog(id) {
	return request({
		url: '/park/specialmonthLog/' + id,
		method: 'get'
	})
}

// 新增月卡操作日志
export function addSpecialmonthLog(data) {
	return request({
		url: '/park/specialmonthLog',
		method: 'post',
		data: data
	})
}

// 修改月卡操作日志
export function updateSpecialmonthLog(data) {
	return request({
		url: '/park/specialmonthLog',
		method: 'put',
		data: data
	})
}

// 删除月卡操作日志
export function delSpecialmonthLog(id) {
	return request({
		url: '/park/specialmonthLog/' + id,
		method: 'delete'
	})
}
