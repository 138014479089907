import request from '@/utils/request'

// 查询停车场信息列表
export function listPark(query) {
  return request({
    url: '/park/park/list',
    method: 'get',
    params: query
  })
}

// 查询停车场信息详细
export function getPark(deptId) {
  return request({
    url: '/park/park/' + deptId,
    method: 'get'
  })
}

// 新增停车场信息
export function addPark(data) {
  return request({
    url: '/park/park',
    method: 'post',
    data: data
  })
}

// 修改停车场信息
export function updatePark(data) {
  return request({
    url: '/park/park',
    method: 'put',
    data: data
  })
}

// 删除停车场信息
export function delPark(deptId) {
  return request({
    url: '/park/park/' + deptId,
    method: 'delete'
  })
}

//修改停车场状态
export function changeParkStatus(deptId, status) {
    const data = { deptId, status }
    return request({
        url: '/park/park/changeStatus',
        method: 'put',
        data: data
    })
}


