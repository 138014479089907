<template>
    <div class="app-container home">
        <div class="content-container">
            <!-- 布局2测试 -->
            <el-row class="row-padding row1" :gutter="36">
                <el-col :span="12" style="height: 100%">
                    <el-row class="row-padding row3" :gutter="36">
                        <el-col class="row4" :span="18">
                            <digital-scroll
                                digitalType="1"
                                :value="digitalValue[0]"
                                :decimals="2"
                                prefix="￥"
                                title="今日停车收入"
                            />
                        </el-col>
                        <el-col class="row4" :span="6">
                            <el-row class="row-padding row2" :gutter="36">
                                <el-col class="row4" :span="24">
                                    <digital-scroll
                                        digitalType="2"
                                        :value="digitalValue[1]"
                                        title="活跃用户数"
                                    />
                                </el-col>
                            </el-row>
                            <el-row class="row-padding row2" :gutter="36">
                                <el-col class="row4" :span="24">
                                    <digital-scroll
                                        digitalType="2"
                                        :value="digitalValue[2]"
                                        title="今日用户数"
                                    />
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class="row-padding row1" :gutter="36">
                        <el-col class="row4" :span="6">
                            <digital-scroll
                                digitalType="2"
                                :value="digitalValue[3]"
                                title="路内车场数"
                            />
                        </el-col>
                        <el-col class="row4" :span="6">
                            <digital-scroll
                                digitalType="2"
                                :value="digitalValue[4]"
                                title="路内泊位数"
                            />
                        </el-col>
                        <el-col class="row4" :span="6">
                            <digital-scroll
                                digitalType="2"
                                :value="digitalValue[5]"
                                title="路外车场数"
                            />
                        </el-col>
                        <el-col class="row4" :span="6">
                            <digital-scroll
                                digitalType="2"
                                :value="digitalValue[6]"
                                title="路外泊位数"
                            />
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6" style="height: 100%">
                    <div class="content">
                        <dv-scroll-board
                            :config="scrollBoardConfig"
                            ref="indexFlowScrollBoard"
                            style="width: 100%; height: 100%"
                        />
                    </div>
                </el-col>
                <el-col :span="6" style="height: 100%">
                    <div class="content">
                        <my-video-player
                            height="100%"
                            width="100%"
                        ></my-video-player>
                    </div>
                </el-col>
            </el-row>
            <el-row class="row-padding row3" :gutter="36">
                <el-col class="row4" :span="12">
                    <el-row class="row-padding row2" :gutter="36">
                        <el-col class="row4" :span="12">
                            <bar-chart height="100%" width="100%"></bar-chart>
                        </el-col>
                        <el-col class="row4" :span="12">
                            <pie-chart />
                        </el-col>
                    </el-row>
                    <el-row class="row-padding row2" :gutter="36">
                        <el-col :span="24" style="height: 100%">
                            <div class="content">
                                <line-chart/>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col class="row4" :span="12">
                    <index-page-map height="100%" width="100%"></index-page-map>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import BarChart from "./dashboard/BarChartDynamic.vue";
import LineChart from "./dashboard/LineChartDynamic.vue";
import DigitalScroll from "./dashboard/DigitalScroll.vue";
import PieChart from "./dashboard/PieChartDynamic.vue";
import MyVideoPlayer from "./dashboard/MyVideoPlayer.vue";
import IndexPageMap from "./dashboard/IndexPageMap.vue";
import { randomNum } from "@/utils/index";
// import resize from './dashboard/mixins/resize'

import {listFlow} from '@/api/park/flow';
import {getIncome} from '@/api/park/statistics';

const inoutLabelMap = {};

export default {
    // mixins: [resize],
    components: {
        BarChart,
        LineChart,
        DigitalScroll,
        PieChart,
        MyVideoPlayer,
        IndexPageMap,
    },
    name: "Index",

    dicts: ['park_channel_type'],

    data() {
        return {
            // 版本号
            version: "1.0.0",
            // 轮播表参数
            scrollBoardConfig: {},
            // 面板数据
            digitalValue: [0, 125, 8, 39, 2924, 7, 673],
            // 今日收入
            todayIncome: 0,
            // 车场统计数据
            parkData: {},

            // 轮播表数据
            scrollBoardData: [],
        };
    },
    created() {
        // this.resetWeatherColor();

    },
    mounted() {
        this.initScrollBoardConfig()
        // let scrollTimer = setInterval(this.initScrollBoardConfig, 5*60*1000)
        this.digitalValueChange();
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    watch: {
        // '$store.state.commonParams.themeParams': {
        //     handler() {
        //         this.resetWeatherColor();
        //     },
        //     deep: true
        // }
    },
    computed: {
        ThemeParam() {
            return ThemeParam;
        },
    },
    methods: {
        onDictReady() {
            var inValue = this.dict.keyValue.park_channel_type.in;
            var outValue = this.dict.keyValue.park_channel_type.out;
            inoutLabelMap[inValue] = "入";
            inoutLabelMap[outValue] = "出";
        },

        goTarget(href) {
            window.open(href, "_blank");
        },
        /**
         * 初始化轮播表数据
         */
        initScrollBoardConfig() {
            let queryParams = {
                pageNum: 1,
                pageSize: 100,
            };
            listFlow(queryParams).then((response) => {
                let sbData = [];
                if (response.rows && response.rows.length > 0) {
                    response.rows.forEach((flowData ,i) => {
                        sbData[i] = [
                            flowData.carNumber,
                            flowData.parkName,
                            inoutLabelMap[flowData.inoutType],
                            this.parseTime(flowData.inoutTime, '{h}:{i}:{s}')
                        ]
                    });
                }
                this.scrollBoardConfig = {
                    header: ["车牌号", "停车场", "入/出", "时间"],
                    data: sbData,
                    headerBGC:
                        this.$store.state.commonParams.themeParams["commonBg2"] || "#f7f9fc",
                    oddRowBGC:
                        this.$store.state.commonParams.themeParams["commonBg3"] || "#edf1f7",
                    evenRowBGC:
                        this.$store.state.commonParams.themeParams["commonBg4"] || "#e4e9f2",
                    columnWidth: [, , ,],
                    align: ["center", "center", "center", "center"],
                    hoverPause: false,
                    headerHeight: 25,
                    rowNum: 8,
                    waitTime: 1200,
                };
            });
        },

        digitalValueChange() {
            let _this = this;
            this.timer = setInterval(function () {
                getIncome({startTime: null, endTime: null}).then(res => {
                    console.log("res")

                    res.data
                })

                _this.$set(
                    _this.digitalValue,
                    0,
                    _this.digitalValue[0] + randomNum(0, 0) / 100
                );
                _this.$set(
                    _this.digitalValue,
                    1,
                    _this.digitalValue[1] + randomNum(0, 1)
                );
                _this.$set(
                    _this.digitalValue,
                    2,
                    _this.digitalValue[2] + randomNum(0, 1)
                );
            }, 60000);
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/guanchao.scss";
.home {
    position: absolute;
    padding: 5px 10px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
}
.map-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}
.index-header {
    position: relative;
    width: 100%;
    height: 36px;
    // background: url("../assets/images/header-title.png") 0 0 no-repeat;
    @include background_color("commonBg2");
    .current-time {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 10px 10px;
        text-align: left;
        // left: 10px;
        @include font_color("commonFgText");
        font-size: $fs5;
    }

    .weather {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 10px 10px;
        @include font_color("commonFgText");
    }
}

.content-container {
    position: relative;
    width: 100%;
    // height: calc(100% - 51px);
    height: 100%;
    top: 10px;

    .row-padding {
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .row1 {
        height: 33.3%;
    }
    .row2 {
        height: 50%;
    }
    .row3 {
        height: 66.6%;
    }
    .row4 {
        height: 100%;
    }
    .row5 {
        height: 16.7%;
    }

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
    }
}

.dv-scroll-board {
    @include font_color("commonFgText", #1a2138);
}
::v-deep .dv-scroll-board .header {
    @include font_color("commonFgText", #1a2138);
    font-size: 12px;
}
::v-deep .dv-scroll-board .rows .row-item {
    font-size: 10px;
    height: 15px;
    @include font_color("commonFgText", #1a2138);
}
</style>

