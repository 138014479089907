import request from '@/utils/request'

// 查询车场计费列表
export function listFeePark(query) {
	return request({
		url: '/pay/feePark/list',
		method: 'get',
		params: query
	})
}

// 查询车场计费详细
export function getFeePark(id) {
	return request({
		url: '/pay/feePark/' + id,
		method: 'get'
	})
}

// 新增车场计费
export function addFeePark(data) {
	return request({
		url: '/pay/feePark',
		method: 'post',
		data: data
	})
}

// 修改车场计费
export function updateFeePark(data) {
	return request({
		url: '/pay/feePark',
		method: 'put',
		data: data
	})
}

// 删除车场计费
export function delFeePark(id) {
	return request({
		url: '/pay/feePark/' + id,
		method: 'delete'
	})
}
// 车场计费列表
export function feeParkList(deptId) {
    return request({
        url: '/pay/feePark/feeParkList/'+deptId,
        method: 'put'
    })
}

// 车场计费列表
export function feeParkAll() {
    return request({
        url: '/pay/feePark/feeParkAll',
        method: 'put'
    })
}



