<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="大场id" prop="bigParkId">
        <el-input
          v-model="queryParams.bigParkId"
          placeholder="请输入大场id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="小场id" prop="smallParkId">
        <el-input
          v-model="queryParams.smallParkId"
          placeholder="请输入小场id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="大场记录id" prop="bigRecordId">
        <el-input
          v-model="queryParams.bigRecordId"
          placeholder="请输入大场记录id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="小场记录id" prop="smallRecordId">
        <el-input
          v-model="queryParams.smallRecordId"
          placeholder="请输入小场记录id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="入场时间" prop="inTime">
        <el-date-picker clearable
          v-model="queryParams.inTime"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择入场时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="出场时间" prop="outTime">
        <el-date-picker clearable
          v-model="queryParams.outTime"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="请选择出场时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['park:nestrecord:add']"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['park:nestrecord:edit']"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['park:nestrecord:remove']"
        >删除</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['park:nestrecord:export']"
        >导出</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="nestrecordList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="id" align="center" prop="id" />
      <el-table-column label="大场名称" align="center" prop="bigParkName" />
      <el-table-column label="小场名称" align="center" prop="smallParkName" />
      <el-table-column label="大场记录id" align="center" prop="bigRecordId" />
      <el-table-column label="小场记录id" align="center" prop="smallRecordId" />
      <el-table-column label="入场时间" align="center" prop="inTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.inTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出场时间" align="center" prop="outTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.outTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="大场金额" align="center" prop="bigMoney" />
      <el-table-column label="小场金额" align="center" prop="smallMoney" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['park:nestrecord:edit']"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['park:nestrecord:remove']"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改嵌套车场记录对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="大场id" prop="bigParkId">
          <el-input v-model="form.bigParkId" placeholder="请输入大场id" />
        </el-form-item>
        <el-form-item label="大场名称" prop="bigParkName">
          <el-input v-model="form.bigParkName" placeholder="请输入大场名称" />
        </el-form-item>
        <el-form-item label="大场祖级关系" prop="bigParkAncestors">
          <el-input v-model="form.bigParkAncestors" placeholder="请输入大场祖级关系" />
        </el-form-item>
        <el-form-item label="小场id" prop="smallParkId">
          <el-input v-model="form.smallParkId" placeholder="请输入小场id" />
        </el-form-item>
        <el-form-item label="小场名称" prop="smallParkName">
          <el-input v-model="form.smallParkName" placeholder="请输入小场名称" />
        </el-form-item>
        <el-form-item label="小场祖级关系" prop="smallParkAncestors">
          <el-input v-model="form.smallParkAncestors" placeholder="请输入小场祖级关系" />
        </el-form-item>
        <el-form-item label="大场记录id" prop="bigRecordId">
          <el-input v-model="form.bigRecordId" placeholder="请输入大场记录id" />
        </el-form-item>
        <el-form-item label="小场记录id" prop="smallRecordId">
          <el-input v-model="form.smallRecordId" placeholder="请输入小场记录id" />
        </el-form-item>
        <el-form-item label="入场时间" prop="inTime">
          <el-date-picker clearable
            v-model="form.inTime"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择入场时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出场时间" prop="outTime">
          <el-date-picker clearable
            v-model="form.outTime"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择出场时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="大场金额" prop="bigMoney">
          <el-input v-model="form.bigMoney" placeholder="请输入大场金额" />
        </el-form-item>
        <el-form-item label="小场金额" prop="smallMoney">
          <el-input v-model="form.smallMoney" placeholder="请输入小场金额" />
        </el-form-item>
        <el-form-item label="删除标记" prop="delFlag">
          <el-input v-model="form.delFlag" placeholder="请输入删除标记" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listNestrecord, getNestrecord, delNestrecord, addNestrecord, updateNestrecord } from "@/api/park/nestrecord";

export default {
  name: "Nestrecord",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 嵌套车场记录表格数据
      nestrecordList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        bigParkId: null,
        smallParkId: null,
        bigRecordId: null,
        smallRecordId: null,
        inTime: null,
        outTime: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询嵌套车场记录列表 */
    getList() {
      this.loading = true;
      listNestrecord(this.queryParams).then(response => {
        this.nestrecordList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        bigParkId: null,
        bigParkName: null,
        bigParkAncestors: null,
        smallParkId: null,
        smallParkName: null,
        smallParkAncestors: null,
        bigRecordId: null,
        smallRecordId: null,
        inTime: null,
        outTime: null,
        bigMoney: null,
        smallMoney: null,
        delFlag: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加嵌套车场记录";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getNestrecord(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改嵌套车场记录";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateNestrecord(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addNestrecord(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除嵌套车场记录编号为"' + ids + '"的数据项？').then(function() {
        return delNestrecord(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('park/nestrecord/export', {
        ...this.queryParams
      }, `nestrecord_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
