import request from '@/utils/request'

// 查询车场设备列表
export function listDevice(query) {
	return request({
		url: '/park/device/list',
		method: 'get',
		params: query
	})
}

// 查询车场设备详细
export function getDevice(id) {
	return request({
		url: '/park/device/' + id,
		method: 'get'
	})
}

// 查询车场设备详细
export function getLedTemplate(id) {
    return request({
        url: '/park/device/getLedTemplate/' + id,
        method: 'get'
    })
}

// 新增车场设备
export function addDevice(data) {
	return request({
		url: '/park/device',
		method: 'post',
		data: data
	})
}

// 修改车场设备
export function updateDevice(data) {
	return request({
		url: '/park/device',
		method: 'put',
		data: data
	})
}

// 删除车场设备
export function delDevice(id) {
	return request({
		url: '/park/device/' + id,
		method: 'delete'
	})
}

// 抬杆
export function requestPole(computer, serialNo, flowId) {
    return request({
        url: '/park/device/pole/' + computer + "/" + serialNo + "/" + flowId,
        method: 'get'
    })
}

// 监控
export function requestMonitor(id) {
    return request({
        url: '/park/device/monitor/' + id,
        method: 'get'
    })
}

// 语音
export function requestVoice(id) {
    return request({
        url: '/park/device/voice/' + id,
        method: 'get'
    })
}

// 屏幕显示
export function requestScreen(id) {
    return request({
        url: '/park/device/screen/' + id,
        method: 'get'
    })
}

// 白名单
export function requestWhiteList(id) {
    return request({
        url: '/park/device/whiteList/' + id,
        method: 'get'
    })
}

// 更新流水车牌号并重新识别
export function updateFlowCarNumber(data) {
    return request({
        url: '/park/device/remate/update/carnumber',
        method: 'post',
        data: data
    })
}

// 触发相机事件，0：抬杆；1-重新触发识别
export function triggerAgain(deviceId) {
    return request({
        url: '/park/device/trigger/again/' + deviceId,
        method: 'post',
        data: {}
    })
}

// 获取设备类型、在线状态统计数据
export function getDeviceStatisticByTypeStatus(data) {
    return request({
        url: '/park/device/deviceStatistic/typeAndStatus',
        method: 'get',
        data: data
    })
}
