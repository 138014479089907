<template>
    <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import resize from "./mixins/resize";

import {getPayTypeStatistic} from '@/api/pay/order';

export default {
    mixins: [resize],

    dicts: ['pay_pay_type'],

    props: {
        className: {
            type: String,
            default: "chart",
        },
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
    },
    data() {
        return {
            chart: null,
            curIndex: 0,
            timer: null,

            chartData: [],
        };
    },
    mounted() {
        this.initData();
        this.$nextTick(() => {
            this.dynamicSelect();
        });
    },
    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        initData() {
            this.chartData = [];
            let params = {
                payTimeBegin: this.parseTime(new Date(new Date().getTime()-7*24*60*60*1000), '{y}-{m}-{d} {h}:{i}:{s}'),
                payTimeEnd: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
            };
            getPayTypeStatistic(params).then((response) => {
                if (response.rows && response.rows.length > 0) {
                    let index = 0
                    response.rows.forEach((ele, _) => {
                        if (ele.payMoney > 0
                            && ele.payType != this.dict.keyValue.pay_pay_type.vip
                            && ele.payType != this.dict.keyValue.pay_pay_type.month) {
                            this.chartData[index] = {name: ele.payTypeLabel, value: ele.payMoney/100}
                            index++
                        }
                    });

                    this.initChart()
                } else {
                    let index = 0
                    this.dict.type.pay_pay_type.forEach((ele, _) => {
                        if (ele.value != this.dict.keyValue.pay_pay_type.vip
                            && ele.value != this.dict.keyValue.pay_pay_type.month) {
                            this.chartData[index] = {name: ele.label, value: 0}
                            index++
                        }
                    })
                    console.log("this.chartData: ", this.chartData)
                    this.initChart()
                }
            });
        },
        initChart() {
            this.chart = this.$echarts.init(this.$el);
            var colorList = [
                "#F3E58D",
                "#02F2F3",
                "#68B5FB",
                "#C0FE5D",
                "#33f",
            ];
            this.chart.setOption({
                // backgroundColor: '#101736',
                title: {
                    text: "支付渠道比例",
                    x: "4%",
                    width: 173,
                    height: 163,
                    fontSize: 20,
                    textStyle: {
                        color: this.$store.state.commonParams.themeParams['commonFgText']|| "#1a2138",
                        fontSize: "18",
                    },
                },
                legend: {
                    left: "right",
                    bottom: "bottom",
                    orient: "vertical",
                    textStyle: {
                        fontSize: "12",
                        color:  this.$store.state.commonParams.themeParams['commonFgText']|| "#1a2138",
                    },
                    itemWidth: 15,
                    itemHeight: 10,
                    height: '100%'
                },
                // tooltip: {
                //     trigger: 'item',
                //     extraCssText: 'z-index: 2',
                // },
                series: [
                    {
                        type: "pie",
                        center: ["45%", "55%"],
                        radius: ["40%", "65%"],
                        clockwise: true,
                        avoidLabelOverlap: true,
                        minAngle: 5,
                        itemStyle: {
                            borderRadius: 2,
                            color: function (params) {
                                return colorList[params.dataIndex % colorList.length];
                            },
                        },
                        label: {
                            show: false,
                            position: "center",
                            lineHeight: 20,
                            formatter: function (params) {
                                if (params.name !== "") {
                                    if (params.percent) {
                                        return (
                                            params.name +
                                            "\n" +
                                            params.percent +
                                            "%"
                                        );
                                    } else {
                                        return (
                                            params.name +
                                            "\n" +
                                            "无"
                                        );
                                    }

                                } else {
                                    return "";
                                }
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        emphasis: {
                            scale: true,
                            scaleSize: 12,
                            label: {
                                show: true,
                                fontSize: "14",
                                fontWeight: "bold",
                                color: this.$store.state.commonParams.themeParams['commonFgText'],
                            },
                        },
                        blur: {
                            label: {
                                show: false,
                            },
                        },
                        data: this.chartData,
                    },
                ],
            });
        },
        dynamicSelect() {
            let _this = this;
            this.timer = setInterval(function () {
                if (!_this.chart) {
                    return;
                }
                // // 隐藏提示框
                // _this.chart.dispatchAction({
                //     type: 'hideTip',
                //     seriesIndex: 0,
                //     dataIndex: _this.curIndex
                // });
                // 显示提示框
                // _this.chart.dispatchAction({
                //     type: 'showTip',
                //     seriesIndex: 0,
                //     dataIndex: _this.curIndex
                // });
                // 取消高亮指定的数据图形
                _this.chart.dispatchAction({
                    type: "downplay",
                    seriesIndex: 0,
                    dataIndex: _this.curIndex == 0 ? _this.chartData.length : _this.curIndex - 1,
                });
                // 高亮显示
                _this.chart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: _this.curIndex,
                });
                _this.curIndex++;
                if (_this.curIndex > _this.chartData.length) {
                    _this.curIndex = 0;
                }
            }, 1000);
        },
    },
};
</script>
