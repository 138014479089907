<template>
    <div class="digital-scroll-container">
        <div :class="'digital-scroll-type-' + digitalType">
            <div class="panel-title">{{ title }}</div>
            <count-to
                :separator="''"
                :prefix="prefix"
                :decimals="decimals"
                :start-val="startValue"
                :end-val="value"
                :duration="1000"
                class="panel-num"
            />
        </div>
    </div>
</template>

<script>
import CountTo from "vue-count-to";

export default {
    // mixins: [resize],
    props: {
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        digitalType: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        value: {
            type: Number,
            default: 0,
        },
        decimals: {
            type: Number,
            default: 0,
        },
        prefix: {
            type: String,
            default: ''
        }
    },
    components: {
        CountTo,
    },
    data() {
        return {
            startValue: 0,
            // endValue: 0,
        };
    },
    watch: {
        value(newVal, oldVal) {
            this.startValue =oldVal
        }
    },
    methods: {
        handleSetLineChartData(type) {
            this.$emit("handleSetLineChartData", type);
        },
    },
};
</script>

<style lang="scss" scoped>
.digital-scroll-container {
    .digital-scroll-type-1 {
        .panel-title {
            font-size: 20px;
        }
        .panel-num {
            font-family: "LESLIE";
            font-size: 72px;
            // font-weight: 700;
            letter-spacing: 10px;
            float: right;
        }
    }
    .digital-scroll-type-2 {
        .panel-title {
            margin-left: 20px;
            font-size: 16px;
        }
        .panel-num {
            font-size: 22px;
            font-weight: 700;
            float: right;
            // margin-right: 20px;
        }
    }
}
</style>
