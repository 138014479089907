<template>
    <div :class="className" :style="{ height: height, width: width }"></div>
</template>

<script>
import resize from "./mixins/resize";
import axios from "axios";
import { Stack } from "@/utils/index";
import {CodeToText, regionData, TextToCode} from 'element-china-area-data';
import {parkRegionDataTreeSelect} from '@/api/park/statistics';

const animationDuration = 3000;

const GEO_JSON_BASE_URL =
    "https://geo.datav.aliyun.com/areas_v3/bound/geojson?code=";
const BASE_ADCODE = "100000";
const mapData = {
    100000: [
        {
            name: "天津",
            value: [117.190182, 39.125596],
            adcode: "120000",
            parkCount: 46,
            berthCount: 3597,
        },
    ],

    120000: [
        {
            name: "东丽区",
            value: [117.3141,39.086657],
            adcode: "120110",
            parkCount: 46,
            berthCount: 3597,
        },
    ],
};
const img2 = 'image://' + require('@/assets/images/park_icon.png');
const imgBorder = 'image://' + require('@/assets/images/border1.png');

export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: "chart",
        },
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
    },
    data() {
        return {
            chart: null,
            mapData: [],
            mapName: "china",
            geoJson: {},
            dataStack: new Stack(),
            parkRegionData: [],
            curParkRegionData: [],
        };
    },
    mounted() {
        this.getParkRegionData();


    },
    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
    },
    methods: {

        getParkRegionData() {
            parkRegionDataTreeSelect(-1).then(response => {
                console.log("response: ", response)
                if (response && response.code === 200 && response.data) {
                    this.parkRegionData = response.data;
                    this.parkRegionDataSetAdcode()

                    this.$nextTick(async () => {
                        this.dataStack.push({
                            name: "全国",
                            adcode: "100000",
                            data: this.parkRegionData
                        });
                        this.curParkRegionData = this.parkRegionData
                        await this.initChart();
                    });
                }
            })
        },

        setCoordinates(geoJson) {
            if (!this.curParkRegionData || !geoJson) {
                return
            }
            for (const regionData of this.curParkRegionData) {
                for (const feature of geoJson.features) {
                    if (regionData.adcode == feature.properties.adcode) {
                        regionData["value"] = feature.properties.center;
                    }
                }
            }
        },

        parkRegionDataSetAdcode() {
            if (this.parkRegionData && this.parkRegionData.length > 0) {
                for (const province of this.parkRegionData) {
                    let provinceName = province.name
                    province["adcode"] = TextToCode[provinceName].code
                    for (const city of province.children) {
                        let cityName = city.name
                        city["adcode"] = TextToCode[provinceName][cityName].code
                        if (!city.adcode) {
                            if (cityName == "市辖区") {
                                cityName = provinceName
                                city["adcode"] = TextToCode[provinceName][cityName].code
                            } else if (cityName == provinceName) {
                                cityName = "市辖区"
                                city["adcode"] = TextToCode[provinceName][cityName].code
                            }
                        }
                        for (const area of city.children) {
                            let areaName = area.name
                            area["adcode"] = TextToCode[provinceName][cityName][areaName].code
                        }
                    }
                }
            }
        },



        async initChart(adcode = BASE_ADCODE, full = true) {
            let _this = this;
            if (!this.chart) {
                this.chart = this.$echarts.init(this.$el);
                this.chart.on("click", this.clickEvent);
                // this.chart.on('mousemove', this.mousemoveEvent)
                // this.chart.on('mouseout', this.mouseoutEvent)
            }
            if (adcode == "100000") {
                this.geoJson = require("@/assets/json/mapGeoJson/" +
                    adcode +
                    ".json");
                this.mapName = "china";
                this.$echarts.registerMap(this.mapName, this.geoJson);
            } else {
                let geoJsonUrl = GEO_JSON_BASE_URL + adcode;
                if (full) {
                    geoJsonUrl = geoJsonUrl + "_full";
                }
                let res = await axios.get(geoJsonUrl)
                if (res && res.data && res.status == 200) {
                    this.geoJson = res.data;
                }
                this.mapName = "other";
                this.$echarts.registerMap(this.mapName, this.geoJson);
            }
            this.setCoordinates(this.geoJson)

            this.chart.setOption({
                title: {
                    text: this.dataStack.peek().name + "停车场数据",
                    left: "50%",
                    textAlign: "center",
                    fontSize: 20,
                    textStyle: {
                        color: "#05F0EE",
                        fontSize: "18",
                    },
                    subtextStyle: {
                        color: "#90979c",
                        fontSize: "16",
                    },
                },
                graphic: [
                    {
                        type: "text",
                        invisible: this.dataStack.size() <= 1,
                        right: "5px",
                        top: "25px",
                        info: "backPrevious",
                        style: {
                            text: "返回上一级",
                            fill: "#05F0EE",
                        },
                    },
                ],
                geo: {
                    map: this.mapName,
                    aspectScale: 0.75, //长宽比
                    zoom: 1.1,
                    roam: false,
                    itemStyle: {
                        areaColor: "#013C62",
                        shadowColor: "#182f68",
                        // shadowOffsetX: 0,
                        // shadowOffsetY: 5,
                    },
                    emphasis: {
                        itemStyle : {
                            areaColor: "#2AB8FF",
                            borderWidth: 0,
                            color: "green",
                            label: {
                                show: false,
                            },
                        }
                    },
                },
                series: [
                    {
                        type: "map",
                        roam: true,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: "rgba(147, 235, 248, 1)",
                            borderWidth: 1.5,
                            areaColor: {
                                type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                                }],
                                globalCoord: false // 缺省为 false
                            },
                        },
                        emphasis: {
                            label: {
                                color: "#fff",
                            },
                            itemStyle: {
                                areaColor: "#389BB7",
                                borderWidth: 0,
                                color: "green",
                            }
                        },
                        zoom: 1.1,
                        roam: false,
                        map: this.mapName, //使用
                        // data: this.difficultData //热力图数据   不同区域 不同的底色
                    },
                    {
                        tooltip: {
                            show: false,
                            extraCssText: 'z-index: 2',
                        },
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        rippleEffect: {
                            scale: 6,
                            brushType: "fill",
                        },
                        showEffectOn: "render",
                        itemStyle: {
                            shadowColor: "#0ff",
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            color: {
                                type: 'linear',
                                x: 1,
                                y: 0,
                                x2: 0,
                                y2: 0,
                                colorStops: [
                                    { offset: 0, color: '#64fbc3' },
                                    { offset: 1, color: '#018ac9' },
                                ],
                                global: false,
                            }
                        },
                        label: {
                            color: "#fff",
                        },
                        symbol: "circle",
                        symbolSize: [10, 5],
                        data: this.curParkRegionData,
                        zlevel: 1,
                    },
                    {
                        type: "scatter",
                        coordinateSystem: "geo",
                        // itemStyle: {
                        //     color: "#ff0",
                        // },
                        // symbol: 'circle',
                        symbol: img2,
                        symbolSize: [30, 30],
                        symbolOffset: [0, -20],
                        z: 9999,
                        data: this.curParkRegionData,
                    },
                    {
                        type: "scatter",
                        coordinateSystem: "geo",
                        label: {
                            show: true,
                            formatter: function (params) {
                                console.log("formatter: ",params);
                                var name = params.name;
                                var parkCount = params.data.parkCount;
                                var parkBerth = params.data.parkBerth;
                                var text = `{tline|${name}}\n{fline|车场数${parkCount}}\n{fline|泊位数${parkBerth}}`;
                                return text;
                            },
                            color: "#fff",
                            rich: {
                                fline: {
                                    padding: [0, 25],
                                    color: "#fff",
                                    // textShadowColor: "#030615",
                                    // textShadowBlur: "0",
                                    // textShadowOffsetX: 1,
                                    // textShadowOffsetY: 1,
                                    fontSize: 14,
                                    fontWeight: 400,
                                },
                                tline: {
                                    padding: [0, 27],
                                    color: "#ABF8FF",
                                    fontSize: 12,
                                },
                            },
                        },
                        itemStyle: {
                            color: "#00FFF6",
                        },
                        emphasis: {
                            label: {
                                show: true,
                            }
                        },
                        symbol: imgBorder,
                        symbolSize: [100, 50],
                        symbolOffset: [0, -60],
                        // z: 999,
                        data: this.curParkRegionData,
                    },
                ],
            });
        },
        async clickEvent(eventParam) {
            console.log("click event param: ", eventParam);
            console.log("geoJson: ", this.geoJson)
            if (eventParam.componentType === "series") {
                if (
                    this.geoJson.features.length <= 1 ||
                    eventParam.dataIndex >= this.geoJson.features.length
                ) {
                    return;
                }
                let childCount = 0, adcode = '';
                if (eventParam.componentSubType == 'map') {
                    childCount = this.geoJson.features[eventParam.dataIndex].properties.childrenNum;
                    adcode = this.geoJson.features[eventParam.dataIndex].properties.adcode;
                } else if (eventParam.componentSubType == 'scatter') {
                    adcode = eventParam.data.adcode;
                    for (const element of this.geoJson.features) {
                        if (element.properties.adcode == adcode) {
                            childCount = element.properties.childrenNum;
                            break;
                        }
                    }
                }
                let cur;
                for (const item of this.curParkRegionData) {
                    if (item.adcode == adcode) {
                        cur = item.children
                        break;
                    }
                }
                console.log("cur: ", cur)
                console.log("cur.name: ", cur.name)
                console.log("this.dataStack.peek().name: ", this.dataStack.peek().name)
                if (cur.length == 1 && (cur[0].name == '市辖区' || cur[0].name == this.dataStack.peek().name)) {
                    this.curParkRegionData = cur[0].children;
                } else {
                    this.curParkRegionData = cur;
                }


                this.dataStack.push({
                    name: eventParam.name,
                    adcode: adcode,
                    data: this.curParkRegionData
                });
                if (childCount > 0) {
                    await this.initChart(adcode, true);
                } else {
                    await this.initChart(adcode, false);
                }
            } else if (eventParam.componentType === "graphic") {
                if (eventParam.info === "backPrevious") {
                    if (this.dataStack.size() <= 1) {
                        return;
                    }
                    this.dataStack.pop();
                    let adcode = this.dataStack.peek().adcode;
                    this.curParkRegionData = this.dataStack.peek().data

                    await this.initChart(adcode, true);
                }
            }
        },
        mousemoveEvent(eventParam) {
            console.log("mousemove event param: ", eventParam);
        },
        mouseoutEvent(eventParam) {
            console.log("mouseout event param: ", eventParam);
        },
    },
};
</script>
