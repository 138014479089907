var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      {
        staticClass: "car-number",
        style: {
          backgroundColor: _vm.backgroundColor,
          color: _vm.fontColor,
          borderColor: _vm.borderColor,
          outlineColor: _vm.backgroundColor,
        },
      },
      [_vm._v(_vm._s(_vm.carNumberStr))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }