<template>
    <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import resize from "./mixins/resize";

import {getParkIncomeStatisticByTime} from '@/api/park/record';

export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: "chart",
        },
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        autoResize: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            chart: null,
            curIndex: 0,
            chartData: {
                xData: [],
                openIncome: [],
                closedIncome: [],
                diciIncome: [],
                legendData: ['路内停车场', '封闭停车场', '地磁停车场'],
            },
            chartRows: 10,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initData();

        });
    },
    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        initData() {
            var today = new Date();
            let param = {
                payTimeBegin: this.parseTime(new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()-30*24*60*60*1000), '{y}-{m}-{d} {h}:{i}:{s}'),
                payTimeEnd: this.parseTime(today, '{y}-{m}-{d} {h}:{i}:{s}'),
            }

            getParkIncomeStatisticByTime(param).then((response) => {
                if (response.rows && response.rows.length > 0) {
                    this.chartRows = response.rows.length
                    for (const r of response.rows) {
                        this.chartData.xData.push(r.dateStr);
                        this.chartData.openIncome.push(r.openParkIncome/100);
                        this.chartData.closedIncome.push(r.closedParkIncome/100);
                        this.chartData.diciIncome.push(r.diciParkIncome/100);
                    }
                }
                this.initChart();
                this.dynamicSelect();
            });
        },

        initChart() {
            this.chart = this.$echarts.init(this.$el);
            this.setOptions(this.chartData);
        },
        setOptions() {
            this.chart.setOption({
                xAxis: {
                    data: this.chartData.xData,
                    boundaryGap: false,
                    axisTick: {
                        show: false,
                    },
                },
                grid: {
                    left: 10,
                    right: 10,
                    bottom: 0,
                    top: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: "axis",
                    extraCssText: 'z-index: 2',
                    backgroundColor: this.$store.state.commonParams.themeParams['commonBg3'] || "#edf1f7",
                    textStyle: {
                        color: this.$store.state.commonParams.themeParams['commonFgText'],
                        fontSize: 12
                    },
                    axisPointer: {
                        type: 'shadow',
                        label: { show: true, backgroundColor: 'transparent' },
                        shadowStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    { offset: 0, color: 'rgba(100, 101, 171, 0.4)' },
                                    { offset: 0.5, color: 'rgba(100, 101, 171, 0.6)' },
                                    { offset: 0.999999, color: 'rgba(100, 101, 171, 1)' },
                                    { offset: 1, color: 'rgba(100, 101, 171, 1)' },
                                ],
                                global: false,
                            },
                        },
                    },
                    padding: [5, 10],
                },
                yAxis: {
                    axisTick: {
                        show: false,
                    },
                },
                legend: {
                    data: this.chartData.legendData,
                    textStyle: {
                        color: this.$store.state.commonParams.themeParams['commonFgText'],

                    },
                },
                series: [
                    {
                        name: this.chartData.legendData[0],
                        itemStyle: {
                            color: "rgba(29, 250, 250, 1)",
                            lineStyle: {
                                color: "rgba(29, 250, 250, 1)",
                                width: 2,
                            },
                            areaStyle: {
                                color: "rgba(29, 250, 250, 0.3)",
                            },
                        },
                        smooth: true,
                        type: "line",
                        data: this.chartData.openIncome,
                        animationDuration: 2800,
                        animationEasing: "cubicInOut",
                    },
                    {
                        name: this.chartData.legendData[1],
                        smooth: true,
                        type: "line",
                        itemStyle: {
                            color: "rgba(241, 93, 35, 1)",
                            lineStyle: {
                                color: "rgba(241, 93, 35, 1)",
                                width: 2,
                            },
                            areaStyle: {
                                color: "rgba(241, 93, 35, 0.3)",
                            },
                        },
                        data: this.chartData.closedIncome,
                        animationDuration: 2800,
                        animationEasing: "cubicInOut",
                    },
                    {
                        name: this.chartData.legendData[2],
                        smooth: true,
                        type: "line",
                        itemStyle: {
                            color: "rgba(182, 162, 222, 1)",
                            lineStyle: {
                                color: "rgba(182, 162, 222, 1)",
                                width: 2,
                            },
                            areaStyle: {
                                color: "rgba(182, 162, 222, 0.3)",
                            },
                        },
                        data: this.chartData.diciIncome,
                        animationDuration: 2800,
                        animationEasing: "cubicInOut",
                    },
                ],
            });
        },
        dynamicSelect() {
            let _this = this;
            this.timer = setInterval(function () {
                if (!_this.chart) {
                    return;
                }
                // // 隐藏提示框
                // _this.chart.dispatchAction({
                //     type: 'hideTip',
                //     seriesIndex: 0,
                //     dataIndex: _this.curIndex
                // });
                // 显示提示框
                _this.chart.dispatchAction({
                    type: 'showTip',
                    seriesIndex: 0,
                    dataIndex: _this.curIndex
                });
                // // 取消高亮指定的数据图形
                // _this.chart.dispatchAction({
                //     type: "downplay",
                //     seriesIndex: 0,
                //     dataIndex: _this.curIndex == 0 ? 5 : _this.curIndex - 1,
                // });
                // _this.chart.dispatchAction({
                //     type: "highlight",
                //     seriesIndex: 0,
                //     dataIndex: _this.curIndex,
                // });
                _this.curIndex++;
                if (_this.curIndex > _this.chartRows) {
                    _this.curIndex = 0;
                }
            }, 500);
        },
    },
};
</script>
