var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          attrs: { trigger: "click", placement: "bottom" },
          on: { command: _vm.handleCommand },
        },
        [
          _c(
            "span",
            { staticClass: "el-dropdown-link" },
            [
              _c("svg-icon", {
                staticClass: "screenfull-svg",
                staticStyle: {
                  width: "22px",
                  height: "22px",
                  "margin-top": "12px",
                },
                attrs: { slot: "reference", "icon-class": "clock" },
                slot: "reference",
              }),
              _vm.counts[0] + _vm.counts[1] + _vm.counts[2] > 0
                ? _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        value: _vm.counts[0] + _vm.counts[1] + _vm.counts[2],
                        size: "mini",
                      },
                    },
                    [_c("span")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["park:recordError:list"],
                      expression: "['park:recordError:list']",
                    },
                  ],
                  attrs: { icon: "el-icon-tickets", command: "record" },
                },
                [
                  _vm._v("停车记录"),
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "32px" } },
                    [
                      _vm.counts[0] > 0
                        ? _c(
                            "el-badge",
                            { staticClass: "item", attrs: { "is-dot": "" } },
                            [
                              _vm._v(
                                _vm._s(_vm.counts[0] > 0 ? _vm.counts[0] : "")
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dropdown-item",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["pay:orderError:list"],
                      expression: "['pay:orderError:list']",
                    },
                  ],
                  attrs: { icon: "el-icon-s-promotion", command: "order" },
                },
                [
                  _vm._v("异常订单"),
                  _vm.counts[1] > 0
                    ? _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          staticStyle: { float: "right" },
                          attrs: { "is-dot": "" },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.counts[1]))])]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-dropdown-item",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:notice:list"],
                      expression: "['system:notice:list']",
                    },
                  ],
                  attrs: {
                    icon: "el-icon-message",
                    divided: "",
                    command: "message",
                  },
                },
                [
                  _vm._v("通知消息"),
                  _vm.counts[2] > 0
                    ? _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          staticStyle: { float: "right" },
                          attrs: { "is-dot": "" },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.counts[2]))])]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-dropdown-item",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["park:push:list"],
                      expression: "['park:push:list']",
                    },
                  ],
                  attrs: { icon: "el-icon-tickets", command: "push" },
                },
                [
                  _vm._v("异常推送"),
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "32px" } },
                    [
                      _vm.counts[3] > 0
                        ? _c(
                            "el-badge",
                            { staticClass: "item", attrs: { "is-dot": "" } },
                            [
                              _vm._v(
                                _vm._s(_vm.counts[3] > 0 ? _vm.counts[3] : "")
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }