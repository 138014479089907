import { DHAlarmWeb } from './dhsdk/dhsdk.min.js'

const CALLBACK_TYEP = ["onLogin", "onDeviceList", "onNotify", "onPlayRT", "onParseMsgError", "onAlarmServerClosed", "onDHAlarmWebError", "onDeviceVideoData", "onWsConnectError"]

export default class DhWeb {
    constructor(serverIp, port, uname, pwd) {
        this.uname = uname
        this.pwd = pwd
        this.serverIp = serverIp
        this.port = port
        this.deviceMap = {}
        this.playDeviceEle = {}
        this.dhAlarmWeb = null
    }
    // 登录
    login() {
        if (this.dhAlarmWeb) {
            console.error("has login")
            this.logout()
        }
        this.dhAlarmWeb = new DHAlarmWeb();
        if (!this.onLogin) {
            console.error("need add onLogin callback")
            return false;
        }
        if (!this.onDeviceList) {
            console.error("need add onDeviceList callback")
            return false;
        }
        if (!this.onNotify) {
            console.error("need add onNotify callback")
            return false;
        }
        this.#bindAllCallback();
        try {
            this.dhAlarmWeb.setWebsocketPort({ dataWsPort: this.port, mediaWsPort: this.port });
            console.log("setWebsocketPort ip:" + this.serverIp + ", port:" + this.port)
            this.dhAlarmWeb.login(this.uname, this.pwd, this.serverIp);
            console.log("login ip:" + this.serverIp + ", port:" + this.port)
            return true;
        } catch (error) {
            if (this.onWsConnectError) {
                this.dhAlarmWeb = null
                this.onWsConnectError(this, error)
            } else {
                console.error(error)
            }
            return false;
        }
    }
    // 登出
    logout() {
        console.log("start logout")
        if (this.dhAlarmWeb) {
            this.dhAlarmWeb.logout(this.loginHandle)
            this.dhAlarmWeb = null;
        }
    }
    // 【私有方法】 登录时用，绑定所有回调
    #bindAllCallback() {
        this.dhAlarmWeb.onLogin = this.onLogin;
        this.dhAlarmWeb.onDeviceList = this.onDeviceList;
        this.dhAlarmWeb.onNotify = this.onNotify;
        this.dhAlarmWeb.onPlayRT = this.onPlayRT;
        this.dhAlarmWeb.onParseMsgError = this.onParseMsgError;
        this.dhAlarmWeb.onAlarmServerClosed = this.onAlarmServerClosed;
        this.dhAlarmWeb.onDHAlarmWebError = this.onDHAlarmWebError;
        this.dhAlarmWeb.onDeviceVideoData = this.onDeviceVideoData;
        this.dhAlarmWeb.onWsConnectError = this.onWsConnectError;
    }
    // 添加回调
    addCallback(type, callback) {
        let that = this
        if (CALLBACK_TYEP.indexOf(type) > -1 && typeof callback === 'function' ) {
            if (type === "onLogin") {
                this[type] = function (message) {
                    if (message && message.error === 'success' && message.params && message.params.loginHandle) {
                        that.loginHandle = message.params.loginHandle
                    } else {
                        console.error("onLogin callback error!")
                        that.logout()
                    }
                    callback(that, message)
                }
            } else {
                this[type] = function (params) {
                    callback(that, params)
                }
            }
            if (this.dhAlarmWeb) {
                this.dhAlarmWeb[type] = this[type]
            }
        }
    }
    // 绑定设备，在接到告警后正确的关联到设备数据
    bindDevice(dhId, viDevice) {
        this.deviceMap[dhId + ""] = viDevice
    }

    getDevice(dhId) {
        if (dhId != null && dhId in this.deviceMap && this.deviceMap[dhId] != null) {
            return this.deviceMap[dhId]
        } else {
            return null
        }
    }

    getDeviceMap() {
        return this.deviceMap
    }

    playRT(el, dhId, openTalk) {
        if (this.playDeviceEle[dhId + ""]) {
            this.dhAlarmWeb.stopRT(dhId, this.loginHandle);
            this.playDeviceEle[dhId + ""].pause();
            this.playDeviceEle[dhId + ""] = null;
        }
        this.playDeviceEle[dhId + ""] = el;

        this.dhAlarmWeb.playRT(el, dhId, this.loginHandle, openTalk)
    }

    stopRT(el, dhId) {
        if (this.playDeviceEle[dhId + ""] && this.playDeviceEle[dhId + ""] == el) {
            this.playDeviceEle[dhId + ""].pause();
            this.playDeviceEle[dhId + ""] = null;
            this.dhAlarmWeb.stopRT(dhId, this.loginHandle);
        }
    }
}

// 创建笛虎客户端
export function createDhWeb(serverIp, port, uname, pwd) {
    return new DhWeb(serverIp, port, uname, pwd);
}
