import request from '@/utils/request'

// 查询月卡、特殊车辆列表
export function listSpecialmonth(query) {
	return request({
		url: '/park/specialmonth/list',
		method: 'get',
		params: query
	})
}

// 查询月卡、特殊车辆详细
export function getSpecialmonth(id) {
	return request({
		url: '/park/specialmonth/' + id,
		method: 'get'
	})
}

// 新增月卡、特殊车辆
export function addSpecialmonth(data) {
	return request({
		url: '/park/specialmonth',
		method: 'post',
		data: data
	})
}

// 修改月卡、特殊车辆
export function updateSpecialmonth(data) {
	return request({
		url: '/park/specialmonth',
		method: 'put',
		data: data
	})
}

// 删除月卡、特殊车辆
export function delSpecialmonth(id) {
	return request({
		url: '/park/specialmonth/' + id,
		method: 'delete'
	})
}

export function getSpecialmonthByCarNumberAndPark(query) {
    return request({
        url: '/park/specialmonth/getByCarNumberAndPark',
        method: 'get',
        params: query
    })
}
