import request from '@/utils/request'

// 查询第三方关联车场列表
export function listParkThrid(query) {
	return request({
		url: '/park/parkThrid/list',
		method: 'get',
		params: query
	})
}

// 查询第三方关联车场详细
export function getParkThrid(id) {
	return request({
		url: '/park/parkThrid/' + id,
		method: 'get'
	})
}

// 新增第三方关联车场
export function addParkThrid(data) {
	return request({
		url: '/park/parkThrid',
		method: 'post',
		data: data
	})
}

// 修改第三方关联车场
export function updateParkThrid(data) {
	return request({
		url: '/park/parkThrid',
		method: 'put',
		data: data
	})
}

// 删除第三方关联车场
export function delParkThrid(id) {
	return request({
		url: '/park/parkThrid/' + id,
		method: 'delete'
	})
}
