
<template>
    <div :id="'container_' + elIdReal" :style="{ height: height, width: width }">
        <div class="title">{{showTitle}}</div>
        <div class="videoBox" :style="fixStyle">
            <video
                width="100%"
                height="100%"
                :id="elIdReal"
                poster="../../assets/images/noVideo.svg"
                muted playsinline
            />

            <div :id="'mask_' + elIdReal" class="playBg" @mouseenter="showBtnHandle" @mouseleave="hideBtnHandle" @click="videoHandle()">
                <div v-if="showBtn">
                    <svg-icon class="playBtn" :icon-class="playStatus ? 'video_pause' : 'video_play'"/>
                    <div class="outter"></div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import resize from "./mixins/resize";
import {listDevice} from "@/api/park/device";

export default {
    dicts: ['park_device_type', 'sys_online_type'],

    mixins: [resize],
    // components: { videoPlayer },
    props: {
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        deviceObj: {
            type: Object,
            default: null,
        },
        playTime: {
            type: Number,
            default: 3*60,
        },
        elId: {
            type: String,
            default: "myVideo",
        },
        title: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            // 视频格式
            fixStyle: {},
            // 当前播放用的笛虎Websocket客户端
            curDhws: null,
            // 当前设备
            curDevice: null,
            // 播放状态
            playStatus: false,
            // 是否显示播放/暂停按钮
            showBtn: true,
            // 视频播放时间定时器
            timer: null,
        };
    },
    watch: {
        // 监听对讲初始化状态的变化
        "$store.state.dhWsStore.initStatus": {
            handler() {
                if (this.$store.state.dhWsStore.initStatus == 1) {
                    this.videoHandle(true);
                }
            },
            deep: true,
        },
        deviceObj: {
            handler(newDevice, oldDevice) {
                console.log("newDevice: ", newDevice&&newDevice.id ,
                    "old device: ", oldDevice&&oldDevice.id);
                this.$nextTick(() => {
                    this.closeVideo(oldDevice)
                    setTimeout(this.videoHandle, true, 100)
                });
            },
            deep: true,
        }
    },
    computed: {
        elIdReal: function() {
            if (this.deviceObj) {
                return this.elId + "_" + this.deviceObj.id;
            } else {
                return this.elId;
            }
        },
        showTitle: function() {
            if (this.title) {
                return this.title
            } else {
                return "实时监控视频" + (this.curDevice ? "-" + this.curDevice.deviceName : "")
            }
        }
    },
    mounted() {
        window.onresize = () => {
            this.onResizeHandler();
        }
        this.onResizeHandler();
        this.videoAddListener();
        if (this.$store.state.dhWsStore.initStatus) {
            this.videoHandle(true);
        }
    },
    beforeUnmount() {
        this.closeVideo();
    },
    methods: {
        onResizeHandler() {
            const parentWidth = document.getElementById('container_' + this.elIdReal).offsetWidth;
            const parentHeight = document.getElementById('container_' + this.elIdReal).offsetHeight - 25;
            const parentAspectRatio = parentHeight / parentWidth;
            let videoWidth;
            let videoHeight;
            if (parentAspectRatio < 9/16) {
                videoHeight = parentHeight;
                videoWidth = parseInt(parentHeight * 16 / 9);
                this.fixStyle = {
                    'height': `${videoHeight}px`,
                    'width': `${videoWidth}px`,
                    'margin-left': `${(parentWidth - videoWidth) / 2}px`,
                    'margin-top': 'initial',
                };
            } else {
                videoWidth = parentWidth;
                videoHeight = parseInt(parentWidth / 16 * 9);

                this.fixStyle = {
                    'height': `${videoHeight}px`,
                    'width': `${videoWidth}px`,
                    'margin-top': `${(parentHeight - videoHeight) / 2}px`,
                    'margin-left': 'initial',
                };
            }
        },

        playVideo() {
            if (this.curDhws != null && this.curDevice != null) {
                console.log("playVideo:", this.curDevice&&this.curDevice.id)
                let cv = document.getElementById(this.elIdReal);
                this.curDhws.playRT(cv, this.curDevice.serialNo, false);
                if (this.playTime > 0) {
                    this.timer = setTimeout(this.closeVideo, this.playTime*1000)
                }
            }
        },
        closeVideo(device) {
            console.log("closeVideo: ", device&&device.id, ", cur:", this.deviceObj&&this.deviceObj.id)
            if (this.curDhws) {
                if (device) {
                    let cv = document.getElementById(this.elId + "_" + device.id);
                    this.curDhws.stopRT(cv, device.serialNo);
                    this.showBtnHandle();
                } else if (this.curDevice) {
                    let cv = document.getElementById(this.elIdReal);
                    this.curDhws.stopRT(cv, this.curDevice.serialNo);
                    this.showBtnHandle();
                }
            }

        },
        /**
         * 视频播放操作，true 开始播放，false 结束播放， null 改变播放状态
         */
        videoHandle(play) {
            console.log("videoHandle", play);
            if (play != null) {
                this.playStatus = play
            } else {
                this.playStatus = !this.playStatus
            }
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer == null
            }
            if (this.playStatus) {
                this.setDevice(() => {
                    this.playVideo()
                    this.hideBtnHandle()
                });
            } else {
                this.closeVideo()
            }
        },

        setDevice(callback) {
            // this.closeVideo();
            // if (this.curDevice && this.curDhws) {
            //     return
            // }
            if (this.deviceObj) {
                this.curDevice = this.deviceObj;
                this.curDhws = this.$store.state.dhWsStore.deviceDhWsMap[this.curDevice.id];
                callback();
            } else {
                let queryParams = {
                    pageNum: 1,
                    pageSize: 99999,
                    deviceType: this.dict.keyValue.park_device_type.videoIntercom,
                    status: this.dict.keyValue.sys_online_type.online,
                }
                let that = this;
                listDevice(queryParams).then(response => {
                    if (response.rows != null && response.rows.length > 0) {
                        let deviceList = response.rows;
                        that.curDevice = deviceList[Math.floor(Math.random()*deviceList.length)];
                        that.curDhws = that.$store.state.dhWsStore.deviceDhWsMap[that.curDevice.id];
                        callback();
                    }
                });
            }


        },

        showBtnHandle() {
            let mask = document.getElementById(`mask_` + this.elIdReal);
            if (mask) {
                mask.style.background = "#1119";
            }
            this.showBtn = true;
        },
        hideBtnHandle() {
            if (this.playStatus) {
                let mask = document.getElementById(`mask_` + this.elIdReal);
                if (mask) {
                    mask.style.background = "#0000";
                }
                this.showBtn = false;
            }

        },

        videoAddListener() {
            let cv = document.getElementById(this.elIdReal);
            cv.addEventListener('pause', () => {
                console.log("video pause!");
                this.videoHandle(false);
            })
        },

    },
};
</script>
<style lang="scss" scoped>
    .title {
        width: 100%;
        height: 25px;
        text-align: left;
        font-size: 16px;
        margin: 0px;
    }
    .videoBox {
        position: absolute;
        @include background_color("commonBg1");
        border: 1px solid;
        @include border_color("commonBorder4");
    }
    .playBg {
        position: relative;
        background: #1119;
        width: 100%;
        height: 100%;
        top: calc(-100% - 4px);  // TODO 因不能完全遮罩视频，上移4像素可以实现，需后续检查原因

        .playBtn {
            position: absolute;
            color: white;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            width: 40px;
            height: 40px;
            background-size: contain;
            cursor: pointer;
        }

        .outter {
            border: 5px solid rgba(255, 255, 255, 0.51);
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -20px;
            margin-top: -20px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            box-sizing: border-box;
        }
        .outter:hover {
            content: "";
            width: 64px;
            height: 64px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -32px;
            margin-top: -32px;
            border-radius: 50%;
            //a1为经过@keyframes 规则建立的动画名称，可本身定义web
            // a2 为动画时常 ，例如：1s   2s   可自行规定浏览器
            // a3 为css自带的动画效果，有以下几个：
            // 类名称+持续时间+（变化方式,延迟时间，重复次数（infinite循环），重复方式，开始结束的状态）
            animation: warn 1s ease-out 0s infinite;
        }
        // 在warn方法里,用百分比来规定变化发生的时间，或用关键词 "from" 和 "to"，等同于 0% 和 100%。
        // 0% 是动画的开始，100% 是动画的完成，建议使用百分比，会更加详细：
        @keyframes warn {
            0% {
                // transform: scale表示缩放，值表示倍数，可以指定X/Y轴
                transform: scale(0.5);
                opacity: 1;
            }

            30% {
                opacity: 1;
            }

            100% {
                transform: scale(1.4);
                opacity: 0;
            }
        }
    }
</style>
