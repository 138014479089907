import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect')
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true
    },
    {
        path: '/register',
        component: () => import('@/views/register'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error/401'),
        hidden: true
    },
    {
        path: '',
        component: Layout,
        redirect: 'index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/index'),
                name: 'Index',
                meta: {title: '首页', icon: 'dashboard', affix: true}
            }
        ]
    },
    {
        path: '/user',
        component: Layout,
        hidden: true,
        redirect: 'noredirect',
        children: [
            {
                path: 'profile',
                component: () => import('@/views/system/user/profile/index'),
                name: 'Profile',
                meta: {title: '个人中心', icon: 'user'}
            }
        ]
    }
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
    {
        path: '/system/user-auth',
        component: Layout,
        hidden: true,
        permissions: ['system:user:edit'],
        children: [
            {
                path: 'role/:userId(\\d+)',
                component: () => import('@/views/system/user/authRole'),
                name: 'AuthRole',
                meta: {title: '分配角色', activeMenu: '/system/user'}
            }
        ]
    },
    {
        path: '/system/role-auth',
        component: Layout,
        hidden: true,
        permissions: ['system:role:edit'],
        children: [
            {
                path: 'user/:roleId(\\d+)',
                component: () => import('@/views/system/role/authUser'),
                name: 'AuthUser',
                meta: {title: '分配用户', activeMenu: '/system/role'}
            }
        ]
    },
    {
        path: '/system/dict-data',
        component: Layout,
        hidden: true,
        permissions: ['system:dict:list'],
        children: [
            {
                path: 'index/:dictId(\\d+)',
                component: () => import('@/views/system/dict/data'),
                name: 'Data',
                meta: {title: '字典数据', activeMenu: '/system/dict'}
            }
        ]
    },
    {
        path: '/monitor/job-log',
        component: Layout,
        hidden: true,
        permissions: ['monitor:job:list'],
        children: [
            {
                path: 'index',
                component: () => import('@/views/monitor/job/log'),
                name: 'JobLog',
                meta: {title: '调度日志', activeMenu: '/monitor/job'}
            }
        ]
    },
    {
        path: '/tool/gen-edit',
        component: Layout,
        hidden: true,
        permissions: ['tool:gen:edit'],
        children: [
            {
                path: 'index/:tableId(\\d+)',
                component: () => import('@/views/tool/gen/editTable'),
                name: 'GenEdit',
                meta: {title: '修改生成配置', activeMenu: '/tool/gen'}
            }
        ]
    }
    ,
    {
        path: '/pay/fee-park',
        component: Layout,
        hidden: true,
        permissions: ['pay:fee:park'],
        children: [
            {
                path: 'list',
                component: () => import('@/views/pay/fee/feePark'),
                name: 'FeePark',
                meta: {title: '车型计费管理', activeMenu: '/fee/park'}
            }
        ]
    },
    {
        path: '/park/record-error',
        component: Layout,
        hidden: true,
        permissions: ['park:recordError:list'],
        children: [
            {
                path: 'list',
                component: () => import('@/views/park/recordError/index'),
                name: 'RecordError',
                meta: {title: '异常停车记录', activeMenu: '/park/record'}
            }
        ]
    },
    {
        path: '/pay/order-error',
        component: Layout,
        hidden: true,
        permissions: ['pay:orderError:list'],
        children: [
            {
                path: 'list',
                component: () => import('@/views/pay/orderError/index'),
                name: 'OrderError',
                meta: {title: '异常订单', activeMenu: '/pay/order'}
            }
        ]
    },
    {
        path: '/system/notice/index',
        component: Layout,
        hidden: true,
        permissions: ['system:notice:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/system/notice/index'),
                name: 'Notice',
                meta: {title: '通知消息', activeMenu: '/system/notice'}
            }
        ]
    },
    {
        path: '/park/dept/thrid',
        component: Layout,
        hidden: true,
        permissions: ['park:parkThrid:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/parkThrid/index'),
                name: 'ParkThrid',
                meta: {title: '第三方关联车场', activeMenu: '/park/park'}
            }
        ]
    },
    {
        path: '/park/specialmonth/log',
        component: Layout,
        hidden: true,
        permissions: ['park:specialmonthLog:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/specialmonth/log/index'),
                name: 'SpecialMonthLog',
                meta: {title: '月卡续费记录', activeMenu: '/park/specialmonth/log'}
            }
        ]
    },
    {
        path: '/park/multiple/flow',
        component: Layout,
        hidden: true,
        permissions: ['park:multipleFlow:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/multipleCarBerth/flow'),
                name: 'MultipleBerthFlow',
                meta: {title: '出入场记录', activeMenu: '/park/multipleCarBerth/flow'}
            }
        ]
    },
    {
        path: '/park/send-error/list',
        component: Layout,
        hidden: true,
        permissions: ['park:push:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/sendError/index'),
                name: 'SendError',
                meta: {title: '异常发送', activeMenu: '/park/park'}
            }
        ]
    },
    {
        path: '/park/restrict',
        component: Layout,
        hidden: true,
        permissions: ['park:restrict:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/restrict/index'),
                name: 'Restrict',
                meta: { title: '限行方案', activeMenu: '/park/device' }
            }
        ]
    },
    {
        path: '/park/device/ledVoiceOption',
        component: Layout,
        hidden: true,
        permissions: ['park:ledVoiceOption:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/device/ledVoiceOption/index'),
                name: 'LedVoiceOption',
                meta: { title: '语音播报列表', activeMenu: '/park/device' }
            }
        ]
    },
    {
        path: '/park/nestpark/park',
        component: Layout,
        hidden: true,
        permissions: ['park:nestpark:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/nestpark/index'),
                name: 'ParkNestpark',
                meta: { title: '嵌套车场', activeMenu: '/park/nestpark' }
            }
        ]
    },
    {
        path: '/park/nestrecord/park',
        component: Layout,
        hidden: true,
        permissions: ['park:nestrecord:list'],
        children: [
            {
                path: '',
                component: () => import('@/views/park/nestrecord/index'),
                name: 'ParkNestRecord',
                meta: { title: '嵌套车场记录', activeMenu: '/park/nestrecord' }
            }
        ]
    },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})
