import request from '@/utils/request'

// 查询车场限行方案数据列表
export function listRestrict(query) {
	return request({
		url: '/park/restrict/list',
		method: 'get',
		params: query
	})
}

// 查询车场限行方案数据详细
export function getRestrict(id) {
	return request({
		url: '/park/restrict/' + id,
		method: 'get'
	})
}

// 新增车场限行方案数据
export function addRestrict(data) {
	return request({
		url: '/park/restrict',
		method: 'post',
		data: data
	})
}

// 修改车场限行方案数据
export function updateRestrict(data) {
	return request({
		url: '/park/restrict',
		method: 'put',
		data: data
	})
}

// 删除车场限行方案数据
export function delRestrict(id) {
	return request({
		url: '/park/restrict/' + id,
		method: 'delete'
	})
}
