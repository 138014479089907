<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App',
    metaInfo() {
        return {
            title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
            titleTemplate: title => {
                return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
            }
        }
    },

    created() {
        this.$store.dispatch('commonParams/initThemeRelationParam', this.$store.state.settings.sideTheme)
        const curTheme = this.$store.state.settings.sideTheme || "theme-default";
        window.document.body.setAttribute("data-theme", curTheme);
    },
}
</script>
