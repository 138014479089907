import request from '@/utils/request'

// 查询月卡操作日志列表
export function parkRegionDataTreeSelect(parentId) {
    return request({
        url: '/park/statistic/parkRegionDataTreeSelect/' + parentId,
        method: 'get'
    })
}

export function getIncome(query) {
    return request({
        url: '/park/statistic/getIncome',
        method: 'get',
        params: query
    })
}
