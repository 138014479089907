import request from '@/utils/request'

// 查询嵌套车场记录列表
export function listNestrecord(query) {
	return request({
		url: '/park/nestrecord/list',
		method: 'get',
		params: query
	})
}

// 查询嵌套车场记录详细
export function getNestrecord(id) {
	return request({
		url: '/park/nestrecord/' + id,
		method: 'get'
	})
}

// 新增嵌套车场记录
export function addNestrecord(data) {
	return request({
		url: '/park/nestrecord',
		method: 'post',
		data: data
	})
}

// 修改嵌套车场记录
export function updateNestrecord(data) {
	return request({
		url: '/park/nestrecord',
		method: 'put',
		data: data
	})
}

// 删除嵌套车场记录
export function delNestrecord(id) {
	return request({
		url: '/park/nestrecord/' + id,
		method: 'delete'
	})
}
