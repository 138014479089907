<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px">
            <el-form-item label="嵌套大场" prop="bigParkId">
                <treeselect v-model="queryParams.bigParkId" :options="parkOptions" :show-count="true"
                               placeholder="请选择嵌套大场" style="width: 220px"/>
            </el-form-item>
            <el-form-item label="嵌套小场" prop="smallParkId">
                <treeselect v-model="queryParams.smallParkId" :options="parkOptions" :show-count="true"
                            placeholder="请选择嵌套小场" style="width: 220px"/>
            </el-form-item>
            <el-form-item label="嵌套关系" prop="type">
                <el-select v-model="queryParams.type" placeholder="请选择嵌套关系" clearable>
                    <el-option
                        v-for="dict in dict.type.park_nest_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['park:nestpark:add']"
                >新增
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="success"
                    plain
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['park:nestpark:edit']"
                >修改
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['park:nestpark:remove']"
                >删除
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:nestpark:export']"
                >导出
                </el-button>
            </el-col>
            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="nestparkList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="id" align="center" prop="id"/>
            <el-table-column label="名称" align="center" prop="name"/>
            <el-table-column label="嵌套大场名字" align="center" prop="bigParkName"/>
            <el-table-column label="嵌套小场名字" align="center" prop="smallParkName"/>
            <el-table-column label="嵌套关系" align="center" prop="type">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.park_nest_type" :value="scope.row.type"/>
                </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="remark"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                        v-hasPermi="['park:nestpark:edit']"
                    >修改
                    </el-button>
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDelete(scope.row)"
                        v-hasPermi="['park:nestpark:remove']"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <!-- 添加或修改嵌套车场对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="850px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-row>
                    <el-col span="12">
                        <el-form-item label="嵌套车场名称" prop="name">
                            <el-input v-model="form.name" placeholder="请输入名称"/>
                        </el-form-item>
                    </el-col>
                    <el-col span="12">
                        <el-form-item label="付款车场" prop="smallParkId">
                            <el-radio-group v-model="form.payPark" size="medium">
                                <el-radio v-for="(dict, index) in dict.type.sys_park_closed_pay_park" :key="index"
                                          :label="dict.value">{{dict.label}}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col span="12">
                        <el-form-item label="嵌套大车场" prop="bigParkId">
                            <treeselect v-model="form.bigParkId" :options="parkOptions" :show-count="true"
                                        placeholder="请选择嵌套大车场" style="width: 100%"/>
                        </el-form-item>
                    </el-col>
                    <el-col span="12">
                        <el-form-item label="嵌套小车场" prop="smallParkId">
                            <treeselect v-model="form.smallParkId" :options="parkOptions" :show-count="true"
                                        placeholder="请选择嵌套小场" style="width:  100%"/>
                        </el-form-item>
                    </el-col>
                    <el-col span="12">
                        <el-form-item label="嵌套关系" prop="type" >
                            <el-select v-model="form.type" placeholder="请选择嵌套关系" style="width:100%">
                                <el-option
                                    v-for="dict in dict.type.park_nest_type"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col span="12" style="height: 70px;">
                        <el-row>
                            <el-image  :src="fullImg[0]" :class="form.type=='full'?'img_select':'img_default'" style="margin-left: 120px;" @click="clickImage('full')" :preview-src-list="fullImg"/>
                            <el-image  :src="fullImg[0]" :class="form.type=='share_in'?'img_select':'img_default'" style="margin-left: 20px;" @click="clickImage('share_in')" :preview-src-list="shareInImg"/>
                            <el-image  :src="fullImg[0]":class="form.type=='share_out'?'img_select':'img_default'" style="margin-left: 20px;" @click="clickImage('share_out')" :preview-src-list="shareOutImg"/>
                        </el-row>
                    </el-col>
                    <el-col span="24">
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {addNestpark, delNestpark, getNestpark, listNestpark, updateNestpark} from "@/api/park/nestpark";
    import {treeselect as menuTreeSelect} from "@/api/system/dept";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";

    export default {
        name: "Nestpark",
        dicts: ['park_nest_type', 'sys_park_closed_pay_park'],
        components: {Treeselect},
        data() {
            return {
                fullImg:[require('@/assets/images/full.png')],
                shareInImg:[require('@/assets/images/full.png')],
                shareOutImg:[require('@/assets/images/full.png')],
                // 车场树选项
                parkOptions: undefined,
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 嵌套车场表格数据
                nestparkList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    bigParkId: null,
                    smallParkId: null,
                    type: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            this.getList();
            this.getParkTree();
        },
        methods: {
            /* 车场列表 */
            getParkTree() {
                menuTreeSelect({type: 1}).then(response => {
                    this.parkOptions = response.data;
                });
            },
            /** 查询嵌套车场列表 */
            getList() {
                this.loading = true;
                listNestpark(this.queryParams).then(response => {
                    this.nestparkList = response.rows;
                    this.total = response.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    name: null,
                    bigParkId: null,
                    bigParkName: null,
                    bigParkAncestors: null,
                    smallParkId: null,
                    smallParkName: null,
                    type: null,
                    remark: null,
                    delFlag: null,
                    createBy: null,
                    createTime: null,
                    updateBy: null,
                    updateTime: null,
                    payPark:'big'
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加嵌套车场";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const id = row.id || this.ids
                getNestpark(id).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改嵌套车场";
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            updateNestpark(this.form).then(response => {
                                this.$modal.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addNestpark(this.form).then(response => {
                                this.$modal.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const ids = row.id || this.ids;
                this.$modal.confirm('是否确认删除嵌套车场编号为"' + ids + '"的数据项？').then(function () {
                    return delNestpark(ids);
                }).then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                }).catch(() => {
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('park/nestpark/export', {
                    ...this.queryParams
                }, `nestpark_${new Date().getTime()}.xlsx`)
            },
            clickImage(type){
                console.log("=====================>", type);
                this.form.type = type;
            }

        }
    };
</script>
<style>
    .img_default{
        width: 60px;
        height: 60px;
        cursor: pointer;
    }

    .img_select{
        width: 60px;
        height: 60px;
        border: 2px solid red;
        cursor: pointer;
    }


</style>
