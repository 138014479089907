<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px">
            <el-form-item label="车牌颜色" prop="carNumberColor">
                <el-select v-model="queryParams.carNumberColor" placeholder="请选择车牌颜色" style="width: 100%">
                    <el-option
                        v-for="dict in dict.type.park_carnumber_color_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label-width="auto" label="车牌号" prop="carNumber">
                <el-input
                    v-model="queryParams.carNumber"
                    placeholder="车牌号"
                    style="width: 150px"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="车场" prop="parkId">
                <treeselect v-model="queryParams.parkId" :options="parkOptions" :show-count="true" placeholder="请选择车场"
                            style="width: 220px"/>
            </el-form-item>
            <el-form-item label-width="auto" label="类型" prop="parkType">
                <el-select v-model="queryParams.parkType" placeholder="请选择类型" clearable style="width: 150px;">
                    <el-option
                        v-for="dict in dict.type.sys_park_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['park:recordError:remove']"
                >删除
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:recordError:export']"
                >导出
                </el-button>
            </el-col>
            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="recordErrorList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="流水Id" align="center" prop="flowId"/>
            <el-table-column label="车牌号" align="center" prop="carNumber"/>
            <el-table-column label="车牌颜色" align="center" prop="carNumberColor">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.park_carnumber_color_type" :value="scope.row.carNumberColor"/>
                </template>
            </el-table-column>
            <el-table-column label="车辆类型" align="center" prop="carType">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.park_car_type" :value="scope.row.carType"/>
                </template>
            </el-table-column>
            <el-table-column label="车场名称" align="center" prop="parkName"/>
            <el-table-column label="通道名称" align="center" prop="channelName"/>
            <el-table-column label="车场名称" align="center" prop="parkName"/>
            <el-table-column label="泊位号" align="center" prop="berthId"/>
            <el-table-column label="发生时间" align="center" prop="createTime"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-user-solid"
                        @click="handleGreyList(scope.row)"
                        v-hasPermi="['park:recordError:edit']"
                    >灰名单
                    </el-button>
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-picture-outline"
                        @click="handleDelete(scope.row)"
                        v-hasPermi="['park:recordError:remove']"
                    >重新匹配入场
                    </el-button>
                    <el-dropdown size="mini" @command="(command) => handleCommand(command, scope.row)"
                                 v-hasPermi="['system:role:edit']">
            <span class="el-dropdown-link">
              <i class="el-icon-d-arrow-right el-icon--right"></i>更多
            </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="handleDataScope" icon="el-icon-edit"
                                              v-hasPermi="['system:role:edit']">修改车牌
                            </el-dropdown-item>
                            <el-dropdown-item command="handleAuthUser" icon="el-icon-watch"
                                              v-hasPermi="['system:role:edit']">修改时间
                            </el-dropdown-item>
                            <el-dropdown-item command="handleAuthUser" icon="el-icon-s-check"
                                              v-hasPermi="['system:role:edit']">审核作废
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />
    </div>
</template>

<script>
    import {
        addRecordError,
        delRecordError,
        getRecordError,
        listRecordError,
        updateRecordError,
        addGreyList
    } from "@/api/park/recordError";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {treeselect as menuTreeSelect} from "@/api/system/dept";

    export default {
        name: "RecordError",
        dicts: ['park_carnumber_color_type', 'sys_park_type', 'park_car_type', 'park_channel_type'],
        components: {Treeselect},
        data() {
            return {
                // 车场树选项
                parkOptions: [],
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 异常停车记录表格数据
                recordErrorList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            this.getList();
            this.getParkTree();
        },
        methods: {
            /* 车场列表 */
            getParkTree() {
                menuTreeSelect({type: 1,containNode: true}).then(response => {
                    this.parkOptions = response.data;
                });
            },
            /** 查询异常停车记录列表 */
            getList() {
                this.loading = true;
                listRecordError(this.queryParams).then(response => {
                    this.recordErrorList = response.rows;
                    this.total = response.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    flowId: null,
                    carNumber: null,
                    carNumberColor: null,
                    carType: null,
                    parkId: null,
                    parkType: null,
                    parkName: null,
                    berthId: null,
                    createTime: null
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加异常停车记录";
            },
            /** 添加灰名单按钮操作 */
            handleGreyList(row) {
                this.reset();
                const id = row.id || this.ids
                addGreyList(id).then(response => {
                    if(response.code === 200){
                        this.$modal.msgSuccess("添加成功");
                    }else{
                        this.$modal.msgError("添加失败");
                    }
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            updateRecordError(this.form).then(response => {
                                this.$modal.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addRecordError(this.form).then(response => {
                                this.$modal.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const ids = row.id || this.ids;
                this.$modal.confirm('是否确认删除异常停车记录编号为"' + ids + '"的数据项？').then(function () {
                    return delRecordError(ids);
                }).then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                }).catch(() => {
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('recordError/recordError/export', {
                    ...this.queryParams
                }, `recordError_${new Date().getTime()}.xlsx`)
            }
        }
    };
</script>
