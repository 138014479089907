<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            size="small"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
        >
            <el-form-item label="语音卡类型" prop="voideCardManufacturer">
                <el-select
                    v-model="queryParams.voideCardManufacturer"
                    placeholder="请输入语音卡类型"
                    clearable
                >
                    <el-option
                        v-for="dict in dict.type.device_manufacturer"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="语音编码" prop="voiceEncode">
                <el-input
                    v-model="queryParams.voiceEncode"
                    placeholder="请输入语音编码"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="版本号" prop="version">
                <el-input
                    v-model="queryParams.version"
                    placeholder="请输入版本号"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="是否支持设置" prop="voiceSupportSetting">
                <el-input
                    v-model="queryParams.voiceSupportSetting"
                    placeholder="请输入是否支持设置"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <!-- <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['park:ledVoiceOption:add']"
        >新增</el-button>
      </el-col> -->
            <el-col :span="1.5">
                <el-button
                    type="success"
                    plain
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['park:ledVoiceOption:edit']"
                    >修改</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-upload2"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['park:ledVoiceOption:import']"
                    >导入</el-button
                >
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:ledVoiceOption:export']"
                    >导出</el-button
                >
            </el-col>
            <right-toolbar
                :showSearch.sync="showSearch"
                @queryTable="getList"
            ></right-toolbar>
        </el-row>

        <el-table
            v-loading="loading"
            :data="ledVoiceOptionList"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="ID" align="center" prop="id" />
            <el-table-column
                label="语音卡类型"
                align="center"
                prop="voideCardManufacturer"
            >
                <template slot-scope="scope">
                    <dict-tag
                        :options="dict.type.device_manufacturer"
                        :value="scope.row.voideCardManufacturer"
                    />
                </template>
            </el-table-column>
            <el-table-column
                label="语音内容"
                align="center"
                prop="voiceContent"
            />
            <el-table-column
                label="语音编码方式"
                align="center"
                prop="voiceEncodeType"
            />
            <el-table-column
                label="语音编码"
                align="center"
                prop="voiceEncode"
            />
            <el-table-column label="版本号" align="center" prop="version" />
            <el-table-column
                label="是否支持设置"
                align="center"
                prop="voiceSupportSetting"
            >
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.voiceSupportSetting"
                        disabled
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="remarks" />
            <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                        v-hasPermi="['park:ledVoiceOption:edit']"
                        >修改</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <!-- 添加或修改LED屏语音播报列表对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="500px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-form-item label="语音卡类型" prop="voideCardManufacturer">
                    <el-select
                        v-model="form.voideCardManufacturer"
                        placeholder="请输入语音卡类型"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="dict in dict.type.device_manufacturer"
                            :key="dict.value"
                            :label="dict.label"
                            :value="dict.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="语音内容" prop="voiceContent">
                    <el-input
                        v-model="form.voiceContent"
                        placeholder="请输入语音内容"
                        disabled
                    />
                </el-form-item>
                <el-form-item label="语音编码" prop="voiceEncode">
                    <el-input
                        v-model="form.voiceEncode"
                        placeholder="请输入语音编码"
                        disabled
                    />
                </el-form-item>
                <el-form-item label="版本号" prop="version">
                    <el-input
                        v-model="form.version"
                        placeholder="请输入版本号"
                        disabled
                    />
                </el-form-item>
                <el-form-item label="是否支持设置" prop="voiceSupportSetting">
                    <el-switch
                        v-model="form.voiceSupportSetting"
                        active-text="开"
                        inactive-text="关"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input
                        v-model="form.remarks"
                        type="textarea"
                        placeholder="请输入内容"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx, .xls"
                :headers="upload.headers"
                :action="upload.url + '?updateSupport=' + upload.updateSupport"
                :disabled="upload.isUploading"
                :on-progress="handleFileUploadProgress"
                :on-success="handleFileSuccess"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip text-center" slot="tip">
                    <div class="el-upload__tip" slot="tip">
                        <el-checkbox v-model="upload.updateSupport" />
                        是否更新已经存在的数据
                    </div>
                    <span>仅允许导入xls、xlsx格式文件。</span>
                    <el-link
                        type="primary"
                        :underline="false"
                        style="font-size: 12px; vertical-align: baseline"
                        @click="importTemplate"
                        >下载模板</el-link
                    >
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFileForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listLedVoiceOption,
    getLedVoiceOption,
    delLedVoiceOption,
    addLedVoiceOption,
    updateLedVoiceOption,
} from "@/api/park/ledVoiceOption";
import { getToken } from "@/utils/auth";

export default {
    name: "LedVoiceOption",
    dicts: ["device_manufacturer"],
    data() {
        return {
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // LED屏语音播报列表表格数据
            ledVoiceOptionList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                voideCardManufacturer: null,
                voiceContent: null,
                voiceEncodeType: null,
                voiceEncode: null,
                version: null,
                voiceSupportSetting: null,
                remarks: null,
            },
            // 导入参数
            upload: {
                // 是否显示弹出层（导入）
                open: false,
                // 弹出层标题（导入）
                title: "",
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的用户数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: "Bearer " + getToken() },
                // 上传的地址
                url:
                    process.env.VUE_APP_BASE_API +
                    "/park/ledVoiceOption/importData",
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {},
        };
    },
    created() {
        this.getList();
    },
    methods: {
        /** 查询LED屏语音播报列表列表 */
        getList() {
            this.loading = true;
            listLedVoiceOption(this.queryParams).then((response) => {
                this.ledVoiceOptionList = response.rows;
                this.total = response.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: null,
                voideCardManufacturer: null,
                voiceContent: null,
                voiceEncodeType: null,
                voiceEncode: null,
                version: null,
                voiceSupportSetting: null,
                remarks: null,
                delFlag: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加LED屏语音播报列表";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids;
            getLedVoiceOption(id).then((response) => {
                this.form = response.data;
                this.open = true;
                this.title = "修改LED屏语音播报列表";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.form.id != null) {
                        updateLedVoiceOption(this.form).then((response) => {
                            this.$modal.msgSuccess("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        addLedVoiceOption(this.form).then((response) => {
                            this.$modal.msgSuccess("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$modal
                .confirm(
                    '是否确认删除LED屏语音播报列表编号为"' + ids + '"的数据项？'
                )
                .then(function () {
                    return delLedVoiceOption(ids);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                "park/ledVoiceOption/export",
                {
                    ...this.queryParams,
                },
                `ledVoiceOption_${new Date().getTime()}.xlsx`
            );
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = "用户导入";
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            this.download(
                "park/ledVoiceOption/importTemplate",
                {},
                `led_voice_option_template_${new Date().getTime()}.xlsx`
            );
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert(
                "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
                    response.msg +
                    "</div>",
                "导入结果",
                { dangerouslyUseHTMLString: true }
            );
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        },
    },
};
</script>
