<template>
  <div>
        <span :style="{backgroundColor: backgroundColor, color: fontColor, borderColor: borderColor, outlineColor: backgroundColor}" class="car-number">{{carNumberStr}}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      levelList: null
    }
  },

  props: ['carNumber','carNumberColor'],
  dicts: ['park_carnumber_color_type'],
  computed: {
    carNumberStr() {
        if (this.carNumber) {
            return this.carNumber
        } else {
            return "无车牌"
        }
    },
    backgroundColor() {
        if (!this.carNumberColor) {
            return "#ffffffff"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.blue) {
            return "#111183"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.yellow) {
            return "#ffbe00"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.green) {
            return "#81c379"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.black) {
            return "#010101"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.white) {
            return "#ffffff"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.other) {
            return "#ffffffff"
        }
    },
    borderColor() {
        if (!this.carNumberColor) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.blue) {
            return "#ffffff"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.yellow) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.green) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.black) {
            return "#ffffff"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.white) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.other) {
            return "#000000"
        }
    },
    fontColor() {
        if (!this.carNumberColor) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.blue) {
            return "#ffffff"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.yellow) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.green) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.black) {
            return "#ffffff"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.white) {
            return "#000000"
        } else if(this.carNumberColor == this.dict.keyValue.park_carnumber_color_type.other) {
            return "#000000"
        }
    }

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.car-number {
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    outline-width: 1px;
    outline-style: solid;
    padding: 4px 6px;
}
</style>
