import request from '@/utils/request'

// 查询呼叫对讲列表
export function listIntercom(query) {
	return request({
		url: '/park/intercom/list',
		method: 'get',
		params: query
	})
}

// 查询呼叫对讲详细
export function getIntercom(id) {
	return request({
		url: '/park/intercom/' + id,
		method: 'get'
	})
}

// 新增呼叫对讲
export function addIntercom(data) {
	return request({
		url: '/park/intercom',
		method: 'post',
		data: data
	})
}

// 修改呼叫对讲
export function updateIntercom(data) {
	return request({
		url: '/park/intercom',
		method: 'put',
		data: data
	})
}

// 删除呼叫对讲
export function delIntercom(id) {
	return request({
		url: '/park/intercom/' + id,
		method: 'delete'
	})
}

export function intercomNotifyFinish(data) {
    return request({
        url: '/park/intercom/onNotify/client/finish',
        method: 'post',
        data: data
    })
}
