<template>
    <div class="top-right-btn" :style="style">
        <el-row>
            <el-tooltip class="item" effect="dark" placement="top" content="搜索" v-if="searchInput">
                <el-input v-model="inputData" placeholder="请输入规则名称" clearable suffix-icon='el-icon-search'
                          :style="{width: '250px'}" size="mini" @change="inputChange"></el-input>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="showSearch ? '隐藏搜索' : '显示搜索'" placement="top"
                        v-if="search">
                <el-button size="mini" circle icon="el-icon-search" @click="toggleSearch()"/>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="刷新" placement="top" v-if="!searchInput">
                <el-button size="mini" circle icon="el-icon-refresh" @click="refresh()"/>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="显隐列" placement="top" v-if="columns">
                <el-button size="mini" circle icon="el-icon-menu" @click="showColumn()"/>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="关闭汇总" placement="top" v-if="summary">
                <el-button size="mini" circle icon="el-icon-s-data" @click="closeSummary()"/>
            </el-tooltip>
        </el-row>
        <el-dialog :title="title" custom-class="transfer-dialog" :visible.sync="open" append-to-body>
            <el-transfer
                :titles="['显示', '隐藏']"
                v-model="value"
                :data="columns"
                @change="dataChange"
            ></el-transfer>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "RightToolbar",
        data() {
            return {
                // 显隐数据
                value: [],
                // 弹出层标题
                title: "显示/隐藏",
                // 是否显示弹出层
                open: false,
                // 搜索输入框的内容
                inputData: null,
            };
        },
        props: {
            showSearch: {
                type: Boolean,
                default: true,
            },
            columns: {
                type: Array,
            },
            search: {
                type: Boolean,
                default: true,
            },
            gutter: {
                type: Number,
                default: 10,
            },
            summary: {
                type: Boolean,
                default: false,
            },
            searchInput: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            style() {
                const ret = {};
                if (this.gutter) {
                    ret.marginRight = `${this.gutter / 2}px`;
                }
                return ret;
            }
        },
        created() {
            // 显隐列初始默认隐藏列
            for (let item in this.columns) {
                if (this.columns[item].visible === false) {
                    this.value.push(parseInt(item));
                }
            }
        },
        methods: {
            // 搜索
            toggleSearch() {
                this.$emit("update:showSearch", !this.showSearch);
            },
            // 刷新
            refresh() {
                this.$emit("queryTable");
            },
            // 关闭汇总
            closeSummary() {
                this.$emit("closeSummary");
            },
            // 搜索输入框
            inputChange() {
                this.$emit("inputChange", this.inputData);
            },
            // 右侧列表元素变化
            dataChange(data) {
                for (let item in this.columns) {
                    const key = this.columns[item].key;
                    this.columns[item].visible = !data.includes(key);
                }
            },
            // 打开显隐列dialog
            showColumn() {
                this.open = true;
            },
        },
    };
</script>
<style lang="scss" scoped>
    ::v-deep .el-transfer__button {
        border-radius: 50%;
        padding: 12px;
        display: block;
        margin-left: 0px;
    }

    ::v-deep .el-transfer__button:first-child {
        margin-bottom: 10px;
    }

    ::v-deep .transfer-dialog {
        width: 545px !important;
    }
</style>
