import request from '@/utils/request'

// 查询停车记录列表
export function listRecord(query) {
  return request({
    url: '/park/record/list',
    method: 'get',
    params: query
  })
}

export function closedParkRecordList(query) {
    return request({
        url: '/park/record/closedParkRecordList',
        method: 'get',
        params: query
    })
}

// 查询停车记录详细
export function getRecord(id) {
  return request({
    url: '/park/record/' + id,
    method: 'get'
  })
}

// 新增停车记录
export function addRecord(data) {
  return request({
    url: '/park/record',
    method: 'post',
    data: data
  })
}

// 修改停车记录
export function updateRecord(data) {
  return request({
    url: '/park/record',
    method: 'put',
    data: data
  })
}

// 停车记录订单
export function getOrdersByRecordId(id) {
    return request({
        url: '/park/record/order/' + id,
        method: 'get'
    })
}

// 停车订单结算
export function settleRecord(recordIds, query) {
    return request({
        url: '/park/record/settle/Wechat/' + recordIds,
        method: 'put',
        params: query
    })
}

// Echarts表单信息汇总
export function refreshSummary(query) {
    return request({
        url: '/park/record/refreshSummary',
        method: 'get',
        params: query
    })
}

// 清楚出场时间
export function clearOutTime(id) {
    return request({
        url: '/park/record/gm/clear/outTime/' + id,
        method: 'post',
    })
}

// 更新车牌号
export function updateCarNumber(id, carNumberColor, carNumber) {
    return request({
        url: '/park/record/gm/updateCarNumber/' + id + "/" + carNumberColor + "/" + carNumber,
        method: 'post',
    })
}

// 更新计费金额
export function updateChargeMoney(id, money) {
    return request({
        url: '/park/record/gm/updateChargeMoney/' + id + "/" + money,
        method: 'post',
    })
}

export function getParkIncomeStatisticByTime(query) {
    return request({
        url: '/park/record/statistic/parkIncome',
        method: 'get',
        params: query
    })
}
