<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px">

            <el-row>
                <el-form-item label="名字" prop="name" label-width="48px">
                    <el-input
                        v-model="queryParams.name"
                        placeholder="请输入名字"
                        clearable
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <!--<el-form-item label="归属部门" prop="deptId">-->
                    <!--<treeselect v-model="queryParams.deptId" :options="deptOptions" :show-count="true"-->
                                <!--style="width: 300px"-->
                                <!--placeholder="请选择归属部门"/>-->
                <!--</el-form-item>-->
                <el-form-item label="计费规则" prop="minCarFeeId">
                    <el-select v-model="queryParams.minCarFeeId"
                               filterable
                               placeholder="请选择"
                               @visible-change="groupFeeRules"
                               :loading="selectLoading">
                        <el-option-group
                            v-for="group in feeRuleGroups"
                            :key="group.label"
                            :label="group.label">
                            <el-option
                                v-for="item in group.feeRule"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-row>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['pay:feePark:add']"
                >新增
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="success"
                    plain
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['pay:feePark:edit']"
                >修改
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['pay:feePark:remove']"
                >删除
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['pay:feePark:export']"
                >导出
                </el-button>
            </el-col>
            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="feeParkList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="id" align="center" prop="id"/>
            <el-table-column label="名称" align="center" prop="name"/>
            <el-table-column label="描述" align="center" prop="description"/>
            <!--<el-table-column label="所属组织机构" align="center" prop="deptId"/>-->
            <el-table-column label="小型车计费" align="center" prop="minCarFeeName"/>
            <el-table-column label="中型车计费" align="center" prop="midCarFeeName"/>
            <el-table-column label="大型车计费" align="center" prop="maxCarFeeName"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click="handleUpdate(scope.row)"
                        v-hasPermi="['pay:feePark:edit']"
                    >修改
                    </el-button>
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click="handleDelete(scope.row)"
                        v-hasPermi="['pay:feePark:remove']"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <!-- 添加或修改车场计费对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
            <el-row :gutter="24">
                <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name" label-width="100px">
                            <el-input v-model="form.name" placeholder="请输入名称"/>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12">
                        <el-form-item label="所属组织机构" prop="deptId" label-width="100px">
                            <treeselect v-model="form.deptId" :options="deptOptions" :show-count="true"
                                        style="width: 100%"
                                        placeholder="请选择归属部门"/>
                        </el-form-item>
                    </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="小型车计费" prop="minCarFeeId" label-width="100px">
                            <el-select v-model="form.minCarFeeId" style="width: 100%"
                                       filterable
                                       placeholder="请选择小型车计费"
                                       @visible-change="groupFeeRules"
                                       :loading="selectLoading">
                                <el-option-group
                                    v-for="group in feeRuleGroups"
                                    :key="group.label"
                                    :label="group.label">
                                    <el-option
                                        v-for="item in group.feeRule"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="中型车计费" prop="midCarFeeId" label-width="100px">
                            <el-select v-model="form.midCarFeeId" style="width: 100%"
                                       filterable
                                       placeholder="请选择中型车计费"
                                       @visible-change="groupFeeRules"
                                       :loading="selectLoading">
                                <el-option-group
                                    v-for="group in feeRuleGroups"
                                    :key="group.label"
                                    :label="group.label">
                                    <el-option
                                        v-for="item in group.feeRule"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="大型车计费" prop="maxCarFeeId" label-width="100px">
                            <el-select v-model="form.maxCarFeeId" style="width: 100%"
                                       filterable
                                       placeholder="请选择大型车计费"
                                       @visible-change="groupFeeRules"
                                       :loading="selectLoading">
                                <el-option-group
                                    v-for="group in feeRuleGroups"
                                    :key="group.label"
                                    :label="group.label">
                                    <el-option
                                        v-for="item in group.feeRule"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="描述" prop="description" label-width="100px">
                            <el-input v-model="form.description" type="textarea" placeholder="请输入内容" :rows="4"/>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {addFeePark, delFeePark, getFeePark, listFeePark, updateFeePark} from "@/api/pay/feePark";
    import {composeList} from "@/api/pay/fee";

    export default {
        name: "FeePark",
        data() {
            return {
                // 分组后所有的计费规则
                feeRuleGroups: [],
                // 下拉框加载中
                selectLoading: false,
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 车场计费表格数据
                feeParkList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    orderByColumn: "id",
                    isAsc: "desc",
                    name: null,
                    deptId: null,
                    minCarFeeId: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            this.getList();
            this.groupFeeRules();
        },
        methods: {
            /** 查询车场计费列表 */
            getList() {
                this.loading = true;
                listFeePark(this.queryParams).then(response => {
                    this.feeParkList = response.rows;
                    this.total = response.total;
                    this.loading = false;
                });
            },

            /** 远程分组计费规则 **/
            groupFeeRules() {
                composeList({}).then(response => {
                    let countList = {label: '按次收费', feeRule: []};
                    let cycleList = {label: '昼夜计费', feeRule: []};
                    let dayNightList = {label: '周期计费', feeRule: []};
                    if (response.data && response.data.length > 0) {
                        response.data.forEach((feeRule, index) => {
                            if (feeRule.type === '0') {
                                countList.feeRule.push({value: feeRule.id, label: feeRule.name});
                            } else if (feeRule.type === '1') {
                                dayNightList.feeRule.push({value: feeRule.id, label: feeRule.name});
                            } else {
                                cycleList.feeRule.push({value: feeRule.id, label: feeRule.name});
                            }
                        });
                    }
                    this.feeRuleGroups = [];
                    this.feeRuleGroups.push(countList);
                    this.feeRuleGroups.push(dayNightList);
                    this.feeRuleGroups.push(cycleList);
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    name: null,
                    description: null,
                    deptId: null,
                    minCarFeeId: null,
                    midCarFeeId: null,
                    maxCarFeeId: null,
                    delFlag: null,
                    createBy: null,
                    createTime: null,
                    updateBy: null,
                    updateTime: null
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加车场计费";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const id = row.id || this.ids
                getFeePark(id).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改车场计费";
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            updateFeePark(this.form).then(response => {
                                this.$modal.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addFeePark(this.form).then(response => {
                                this.$modal.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const ids = row.id || this.ids;
                this.$modal.confirm('是否确认删除车场计费编号为"' + ids + '"的数据项？').then(function () {
                    return delFeePark(ids);
                }).then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                }).catch(() => {
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('fee/feePark/export', {
                    ...this.queryParams
                }, `feePark_${new Date().getTime()}.xlsx`)
            }
        }
    };
</script>
