import request from '@/utils/request'

// 查询LED屏语音播报列表列表
export function listLedVoiceOption(query) {
	return request({
		url: '/park/ledVoiceOption/list',
		method: 'get',
		params: query
	})
}

// 查询LED屏语音播报列表详细
export function getLedVoiceOption(id) {
	return request({
		url: '/park/ledVoiceOption/' + id,
		method: 'get'
	})
}

// 新增LED屏语音播报列表
export function addLedVoiceOption(data) {
	return request({
		url: '/park/ledVoiceOption',
		method: 'post',
		data: data
	})
}

// 修改LED屏语音播报列表
export function updateLedVoiceOption(data) {
	return request({
		url: '/park/ledVoiceOption',
		method: 'put',
		data: data
	})
}

// 删除LED屏语音播报列表
export function delLedVoiceOption(id) {
	return request({
		url: '/park/ledVoiceOption/' + id,
		method: 'delete'
	})
}
