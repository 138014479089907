import request from '@/utils/request'

// 查询嵌套车场列表
export function listNestpark(query) {
	return request({
		url: '/park/nestpark/list',
		method: 'get',
		params: query
	})
}

// 查询嵌套车场详细
export function getNestpark(id) {
	return request({
		url: '/park/nestpark/' + id,
		method: 'get'
	})
}

// 新增嵌套车场
export function addNestpark(data) {
	return request({
		url: '/park/nestpark',
		method: 'post',
		data: data
	})
}

// 修改嵌套车场
export function updateNestpark(data) {
	return request({
		url: '/park/nestpark',
		method: 'put',
		data: data
	})
}

// 删除嵌套车场
export function delNestpark(id) {
	return request({
		url: '/park/nestpark/' + id,
		method: 'delete'
	})
}
